import Highlighter from 'react-highlight-words';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';
import { Search, XIcon } from 'lucide-react';

import { FormInput } from '@/components/FormInput';
import { Input } from '@/components/Input/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { TabHeading } from '@/components/TabHeading';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';

const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

const unwantedFilterKeys = ['audience', 'search'];

function returnPillLabel(key, filter) {
  if (key === 'market') {
    return `${filter.value.label.toLowerCase()}, ${filter.value.location}`;
  }

  if (key === 'personPosition') {
    return `Person Position: ${filter.value.label.toLowerCase()}`;
  }

  if (key === 'personCount') {
    return `Person Count: ${filter.value.label.toLowerCase()}`;
  }

  return (
    filter.value.label ||
    filter.value.name ||
    filter.value[0].label
  ).toLowerCase();
}

function ExploreSort() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaSort, handleSocialMediaSortOnChange, activeTab } =
    useExploreContext();

  if (!exploreAdvanced || activeTab === 'fashion-weeks') {
    return null;
  }

  return Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
    <FormInput className="w-auto shrink-0" key={i}>
      <SelectDropdown
        placeholder={sort?.placeholder}
        isSearchable={false}
        options={sort?.options}
        multiselect={false}
        isClearable={true}
        value={sort?.value}
        key={sort?.key}
        onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
      />
    </FormInput>
  ));
}

function ExploreFilterPills() {
  const {
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    handleFashionWeekFiltersOnChange,
    handleSocialMediaFiltersOnChange,
  } = useExploreContext();

  function handleRemovePill(key, filter, attribute) {
    if (activeTab === 'fashion-weeks') {
      handleFashionWeekFiltersOnChange(null, key);
    } else {
      if (key === 'attribute.custom') {
        const newFilters = filter.value.filter(
          (attr) => attr.value !== attribute.value,
        );

        if (newFilters.length === 0) {
          handleSocialMediaFiltersOnChange(null, key, true);
        } else {
          handleSocialMediaFiltersOnChange(newFilters, key, true);
        }
      } else {
        handleSocialMediaFiltersOnChange(null, key);
      }
    }
  }

  const pills = Object.entries(
    activeTab === 'fashion-weeks' ? fashionWeekFilters : socialMediaFilters,
  ).filter(([key, pill]) => pill.value && !unwantedFilterKeys.includes(key));

  return (
    <div className="no-scrollbar flex w-full items-center gap-6 overflow-x-scroll pr-4">
      {pills.length === 0 ? (
        <div className=" flex shrink-0 items-center gap-2 rounded-[0.25rem] bg-[#A1A1A114] px-4 py-[0.625rem] text-[#1A1A1A]">
          <span
            className={cn('text-[0.875rem] font-medium capitalize leading-5')}
          >
            {activeTab === 'fashion-weeks'
              ? 'All Fashion Weeks'
              : 'All Markets'}
          </span>
        </div>
      ) : (
        pills.map(([key, filter]) =>
          key === 'attribute.custom' ? (
            filter?.value?.map((attribute) => (
              <div
                className="flex shrink-0 items-center gap-2 rounded-[0.25rem] bg-[#A1A1A114] px-4 py-[0.625rem] text-[#1A1A1A]"
                key={attribute.name}
              >
                <span
                  className={cn(
                    'text-[0.875rem] font-medium capitalize leading-5',
                    key !== 'platform' && 'mr-4 ',
                  )}
                >
                  {attribute.name} : {attribute.value}
                </span>
                <button
                  onClick={() => handleRemovePill(key, filter, attribute)}
                >
                  <XIcon size={16} />
                </button>
              </div>
            ))
          ) : (
            <div
              className="flex shrink-0 items-center gap-2 rounded-[0.25rem] bg-[#A1A1A114] px-4 py-[0.625rem] text-[#1A1A1A]"
              key={key}
            >
              {filter?.value?.hex && (
                <div
                  className="h-4 w-4 rounded-full"
                  style={{
                    background: filter?.value?.hex,
                  }}
                />
              )}
              <span
                className={cn(
                  'text-[0.875rem] font-medium capitalize leading-5',
                  key !== 'platform' && 'mr-4 ',
                )}
              >
                {returnPillLabel(key, filter)}
              </span>
              {key !== 'platform' && (
                <button onClick={() => handleRemovePill(key, filter)}>
                  <XIcon size={16} />
                </button>
              )}
            </div>
          ),
        )
      )}
    </div>
  );
}

function ExploreSocialMediaSearch() {
  const { socialMediaFilters, handleSocialMediaFiltersOnChange } =
    useExploreContext();

  const search = socialMediaFilters?.search;

  return (
    <div className="gap-6border-[#1A1A1A] flex min-w-[20rem] items-center gap-2 rounded-md border-[.5px] border-[#1A1A1A] bg-white px-4 py-2 outline-none">
      <Search />
      <Input
        type="text"
        placeholder={search.placeholder}
        value={search.value}
        onChange={(e) =>
          handleSocialMediaFiltersOnChange(e.target.value, 'search')
        }
        className="text-[0.875rem] font-normal leading-5 outline-none"
      />
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const searchFilter = fashionWeekFilters?.search;

  const debouncedLoadOptions = debounce(loadFashionWeekSearchOptions, 300);

  return (
    <div className="min-w-[20rem]">
      <FormInput>
        <SelectDropdown
          placeholder={searchFilter?.placeholder}
          isSearchable={searchFilter?.isSearchable}
          options={searchFilter?.options}
          multiselect={false}
          isClearable={searchFilter?.isClearable}
          value={searchFilter?.value}
          key={'search'}
          onChange={(e) => handleFashionWeekFiltersOnChange(e, 'search')}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={debouncedLoadOptions}
          defaultOptions
          classNames={{
            control: 'min-w-[20rem]',
            valueContainer: 'min-w-[12rem]',
          }}
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          noOptionsMessage={() => 'No results found'}
          icon={<Search />}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </div>
  );
}

export function ExploreTabs({ className }) {
  const { activeTab, setActiveTab } = useExploreContext();

  return (
    <div
      className={cn('h-full w-full overflow-hidden relative pt-6', className)}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative flex h-full flex-col gap-6'}
      >
        <div className="flex items-center justify-between px-[3.75rem]">
          <Tabs.List
            aria-label="tabs"
            className={'flex w-full items-center justify-between'}
          >
            <div className={'flex gap-12'}>
              {tabs.map((tab, i) => (
                <TabHeading label={tab.label} value={tab.value} key={i} />
              ))}
            </div>
          </Tabs.List>

          {activeTab === 'social-media' ? (
            <ExploreSocialMediaSearch />
          ) : (
            <ExploreFashionWeekSearch />
          )}
        </div>

        <div className="flex w-full items-center justify-between px-[3.75rem]">
          <ExploreFilterPills />
          <ExploreSort />
        </div>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={
              'h-full w-full overflow-x-hidden overflow-y-scroll px-[3.75rem]'
            }
          >
            <tab.Component />
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
}
