import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { STUDIO_TASK } from '@/api/studio/mutations';
import {
  FASHION_WEEK_ITEM,
  GET_SIMILAR_IMAGES,
  GET_STUDIO_ITEM,
  SOCIAL_MEDIA_ITEM,
  UPLOAD_ITEM_IMAGE,
} from '@/api/studio/queries';
import { useStudioContext } from '@/contexts/StudioContext';
import { downloadFile } from '@/helpers/utils';

import { AsideBar, Collections } from './components/AsidebarMenu';
import { StudioExploreSideBar } from './components/StudioExploreSideBar';
import { StudioHistorySidebar } from './components/StudioHistorySidebar';
import { ImageDisplay } from './StudioGeneration';
import { StudioHome } from './StudioHome';
import { StudioLeftSidebar } from './StudioLeftSidebar';
import { StudioTools } from './StudioTools';

export const StudioLayout = () => {
  const [refImages, setRefImages] = useState({
    images: [],
    patterns: [],
    sketches: [],
  });
  const navigate = useNavigate();

  const location = useLocation();

  const {
    activeModal,
    setActiveModal,
    handleTitleIconChanges,
    generatedImages,
    setGeneratedImages,
    orientation,
    setIsTile,
    similarData,
    similarLoading,
    similarError,
    openTools,
    setOpenTools,
    updateDesignTask,
    setSelectedGeneratedImage,
    setIsPreview,
    setReferencePattern,
    setReferenceImage,
    setIsGenerating,
    isGenerating,
  } = useStudioContext();

  const isHome = location.pathname.includes('home');

  const searchParams = new URLSearchParams(location.search);
  const isEditing = searchParams.get('isEditing') === 'true';

  useEffect(() => {
    setGeneratedImages([]);
    setIsTile(false);
    setSelectedGeneratedImage(0);
    setIsPreview(false);
    setIsGenerating(false);
    setReferencePattern(null);
    setReferenceImage(null);
    setRefImages({ images: [], patterns: [], sketches: [] });
  }, [isEditing]);

  // const validParams = ['isEditing', 'taskId', 'itemId', 'itemType'];

  // const areRequiredParamsMissing = validParams.some((param) =>
  //   searchParams.has(param),
  // );

  const [studioTask, { loading: studioLoading }] = useMutation(STUDIO_TASK, {
    variables: {
      studioTaskId: searchParams.get('taskId'),
    },
    onCompleted: (data) => {
      Object.values(data).forEach((value) => {
        setGeneratedImages(value.images);
      });
      if (data?.studioTask?.status === 'COMPLETED') {
        updateDesignTask({
          payload: data.studioTask.payload,
          loading: false,
          result: data.studioTask,
          ...data.studioTask,
        });
        return true;
      }
      // setIsTile(true);
    },
  });

  const [fashionWeekItem, { loading: fashionWeekItemLoading }] = useLazyQuery(
    FASHION_WEEK_ITEM,
    {
      variables: {
        itemId: searchParams.get('itemId'),
      },
      onCompleted: (data) => {
        updateDesignTask({
          payload: {
            positivePrompt: '',
            orientation: {
              label: 'portrait',
              value: 'PORTRAIT',
            },
            referenceImage: {
              entityType: 'FASHION_WEEK',
              entityId: data.fashionWeekItem.photo.id,
            },
          },
          images: [
            {
              ...data.fashionWeekItem.photo.image,
              studioItemId: data.fashionWeekItem.photo.id,
            },
          ],
          loading: false,
          result: null,
        });

        setRefImages({
          images: [
            {
              ...data.fashionWeekItem.photo.image,
              studioItemId: data.fashionWeekItem.photo.id,
              entityType: 'FASHION_WEEK_ITEM',
              entityId: data.fashionWeekItem.photo.id,
            },
          ],
          patterns: [],
          sketches: [],
        });

        setGeneratedImages([
          {
            ...data.fashionWeekItem.photo.image,
            studioItemId: data.fashionWeekItem.photo.id,
          },
        ]);
        setIsTile(false);
        setSelectedGeneratedImage(0);
        setIsPreview(true);
      },
    },
  );

  const [socialMediaItem, { loading: socialMediaItemLoading }] = useLazyQuery(
    SOCIAL_MEDIA_ITEM,
    {
      variables: {
        postId: searchParams.get('itemId'),
      },
      onCompleted: (data) => {
        setGeneratedImages([
          {
            ...data.post.image,
            studioItemId: data.post.id,
          },
        ]);
        setIsTile(false);
        setSelectedGeneratedImage(0);
        setIsPreview(true);

        setRefImages({
          images: [
            {
              ...data.post.image,
              studioItemId: data.post.id,
              entityType: 'SOCIAL_MEDIA_ITEM',
              entityId: data.post.id,
            },
          ],
          patterns: [],
          sketches: [],
        });
      },
    },
  );

  const [uploadItemImage, { loading: uploadItemImageLoading }] = useLazyQuery(
    UPLOAD_ITEM_IMAGE,
    {
      variables: {
        uploadItemImageId: searchParams.get('itemId'),
      },
      onCompleted: (data) => {
        setGeneratedImages([
          {
            ...data.uploadItemImage.node.image,
            width: data.uploadItemImage.node.image.width
              ? data.uploadItemImage.node.image.width
              : 950,
            height: data.uploadItemImage.node.image.height
              ? data.uploadItemImage.node.image.height
              : 1150,
            studioItemId: data.uploadItemImage.node.id,
          },
        ]);
        setIsTile(false);
        setSelectedGeneratedImage(0);
        setIsPreview(true);

        if (data.uploadItemImage.node.fileEntityType === 'PATTERN') {
          setRefImages({
            images: [],
            sketches: [],
            patterns: [
              {
                ...data.uploadItemImage.node.image,
                studioItemId: data.uploadItemImage.node.id,
                entityType: 'FILE_UPLOAD',
                entityId: data.uploadItemImage.node.id,
              },
            ],
          });
        } else if (data.uploadItemImage.node.fileEntityType === 'APPAREL') {
          setRefImages({
            patterns: [],
            sketches: [],
            images: [
              {
                ...data.uploadItemImage.node.image,
                studioItemId: data.uploadItemImage.node.id,
                entityType: 'FILE_UPLOAD',
                entityId: data.uploadItemImage.node.id,
              },
            ],
          });
        } else if (data.uploadItemImage.node.fileEntityType === 'SKETCH') {
          setRefImages({
            patterns: [],
            images: [],
            sketches: [
              {
                ...data.uploadItemImage.node.image,
                studioItemId: data.uploadItemImage.node.id,
                entityType: 'FILE_UPLOAD',
                entityId: data.uploadItemImage.node.id,
              },
            ],
          });
        }
      },
    },
  );

  const [studioItem, { loading: studioItemLoading }] = useLazyQuery(
    GET_STUDIO_ITEM,
    {
      variables: {
        id: searchParams.get('itemId'),
      },
      onCompleted: (data) => {
        setGeneratedImages([
          {
            ...data.studioItem.node.image,
            width: data.studioItem.node.image.width,
            height: data.studioItem.node.image.height,
            studioItemId: data.studioItem.node.id,
          },
        ]);
        setIsTile(false);
        setSelectedGeneratedImage(0);
        setIsPreview(true);

        if (data.studioItem.node.generateTaskType === 'generate_image') {
          setRefImages({
            images: [
              {
                ...data.studioItem.node.image,
                studioItemId: data.studioItem.node.id,
                entityType: 'STUDIO_ITEM',
                entityId: data.studioItem.node.id,
              },
            ],
            patterns: [],
            sketches: [],
          });
        } else if (
          data.studioItem.node.generateTaskType === 'generate_pattern'
        ) {
          setRefImages({
            images: [],
            patterns: [
              {
                ...data.studioItem.node.image,
                studioItemId: data.studioItem.node.id,
                entityType: 'STUDIO_ITEM',
                entityId: data.studioItem.node.id,
              },
            ],
            sketches: [],
          });
        }
      },
    },
  );

  const [
    similarItems,
    {
      data: similarItemData,
      loading: similarItemLoading,
      error: similaItemError,
    },
  ] = useLazyQuery(GET_SIMILAR_IMAGES);

  useEffect(() => {
    if (searchParams.has('taskId')) {
      studioTask({
        variables: {
          id: searchParams.get('taskId'),
        },
        onCompleted: (data) => {
          if (data?.studioTask?.status === 'COMPLETED') {
            setGeneratedImages(data.studioTask.images);
          }
          similarItems({
            variables: {
              payload: data?.studioTask?.payload,
              generationType:
                data?.studioTask?.generateTaskType === 'generate_pattern'
                  ? 'PATTERN'
                  : 'APPAREL',
            },
          });
        },
      });
    }
  }, [searchParams.has('taskId')]);

  // useEffect(() => {
  //   if (!areRequiredParamsMissing) {
  //     navigate('/studio/home');
  //   }
  // }, [areRequiredParamsMissing]);

  useEffect(() => {
    if (isEditing) {
      setOpenTools(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (
      searchParams.has('itemType') &&
      searchParams.get('itemType') === 'fashionWeekItem'
    ) {
      fashionWeekItem();
    } else if (
      searchParams.has('itemType') &&
      searchParams.get('itemType') === 'socialMediaItem'
    ) {
      socialMediaItem();
    } else if (
      searchParams.has('itemType') &&
      searchParams.get('itemType') === 'fileUploadItem'
    ) {
      uploadItemImage();
    } else if (
      searchParams.has('itemType') &&
      searchParams.get('itemType') === 'studioItem'
    ) {
      studioItem();
    }
  }, [searchParams.has('itemType')]);

  return (
    <div className="grid h-full grid-cols-[1fr,4.5fr] items-start justify-start">
      {/* Sidebar */}
      <StudioLeftSidebar isEditing={isEditing} refImages={refImages} />
      {/* Complement of Sidebar */}
      <div className="relative grid h-full grid-cols-[1fr,7rem] overflow-hidden">
        {/* Main Section */}
        <div className="flex flex-col overflow-auto border-r border-secondary/50 bg-[#FAF8F8] p-6">
          {/* Home Page */}
          {isHome && <StudioHome handleChange={handleTitleIconChanges} />}
          {/* Create an Apparel Page */}
          {!isHome && (
            <div
              className={`relative ${openTools ? 'flex w-3/5 flex-col' : 'flex h-full flex-col md:grid md:grid-cols-[1fr,0.8fr]'} items-start justify-start`}
            >
              {studioLoading && !isGenerating && (
                <div className="flex min-w-[40rem] max-w-[50rem] flex-col gap-6 rounded-lg border border-secondary/50 bg-primaryLight p-6">
                  <h3 className="text-2xl font-bold leading-6 text-primary">
                    Results
                  </h3>
                  <div className="grid grid-cols-2 grid-rows-2 gap-6">
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div
                        key={index}
                        className="ghost aspect-square rounded"
                      ></div>
                    ))}
                  </div>
                </div>
              )}
              {!studioLoading && !fashionWeekItemLoading && (
                <ImageDisplay
                  generatedImages={generatedImages}
                  orientation={orientation}
                  downloadFile={downloadFile}
                />
              )}
              <div className="flex w-full flex-col gap-y-6 p-6" id="more-ideas">
                <div>
                  <h2
                    className="text-2xl font-bold leading-6 text-primary"
                    inert=""
                  >
                    More ideas to explore
                  </h2>
                </div>
                <div
                  className={`grid ${openTools ? 'grid-cols-4' : 'grid-cols-3 sm:grid-cols-4 md:grid-cols-3'} mb-20 gap-6`}
                >
                  {(similarLoading ||
                    similarItemLoading ||
                    socialMediaItemLoading ||
                    uploadItemImageLoading ||
                    studioItemLoading) &&
                    Array.from({ length: 16 }).map((_, index) => (
                      <div
                        key={index}
                        className="ghost aspect-[3/4] rounded"
                      ></div>
                    ))}
                  {(similarData || similarItemData)?.studioSimilarImages?.map(
                    (data, index) => {
                      const startIndex = data.node.image.url.indexOf('/v3');
                      const endIndex = data.node.image.url.indexOf('.jpg') + 4;
                      const extractedPath = data.node.image.url.slice(
                        startIndex,
                        endIndex,
                      );
                      const newUrl = `https://assets.tfashion.ai${extractedPath}?format=auto`;
                      return (
                        <div
                          key={index}
                          className="aspect-[3/4] cursor-pointer rounded transition-transform hover:scale-105 hover:shadow"
                          onClick={() =>
                            navigate(
                              `/studio/v1/image-preview/studioItem/${data.node.id}`,
                            )
                          }
                        >
                          <img
                            src={
                              import.meta.env.VITE_APP_ENV === 'development'
                                ? newUrl
                                : data.node.image.url
                            }
                            alt={data.node.image.studioItemId}
                            className="h-full w-full rounded object-cover"
                          />
                        </div>
                      );
                    },
                  )}
                  {(similarError || similaItemError) && (
                    <div className="w-60 text-base font-semibold text-black">
                      More ideas failed to load
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {openTools && <StudioTools />}
        </div>
        {/* AsideBar */}
        <AsideBar activeModal={activeModal} setActiveModal={setActiveModal} />
        {/* Collection Modal */}
        {activeModal === 'collections' && (
          <Collections setActiveModal={setActiveModal} />
        )}
        {activeModal === 'history' && (
          <StudioHistorySidebar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'designs' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'patterns' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'sketches' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
      </div>
    </div>
  );
};
