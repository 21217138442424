import { useState } from 'react';
import dayjs from 'dayjs';
import { useElementSize } from 'usehooks-ts';

import { cn } from '@/helpers/utils';

import { CardContext } from '../context';

import { Moderation } from './Moderation';

import styles from '../index.module.css';

function nameFormat(input) {
  const withSpaces = input.replace(/[-,]/g, ' ');
  const titleCased = withSpaces
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return titleCased;
}

function seasonFormat(input) {
  return input
    .split(' ')
    .map((part) => {
      const season = part.includes('fall-winter') ? 'FW' : 'SS';
      const year = part.match(/\d{4}/)[0].slice(2);
      return `${season}${year}`;
    })
    .join(' ');
}

function likeFormat(input) {
  if (input >= 1000 && input < 1000000) {
    const formatted = (input / 1000).toFixed(1);

    return formatted.endsWith('.0')
      ? `${parseInt(formatted, 10)}K`
      : `${formatted}K`;
  } else if (input >= 1000000) {
    const formatted = (input / 1000000).toFixed(1);

    return formatted.endsWith('.0')
      ? `${parseInt(formatted, 10)}M`
      : `${formatted}M`;
  }

  return input.toString();
}

function dateFormat(input) {
  return dayjs(input).format('DD MMM YY');
}

const SocialMediaItemDetails = ({ post }) => {
  return (
    <div className="flex flex-col gap-y-1 bg-white py-2 px-1 text-left">
      <div className="flex flex-row items-center justify-between">
        <p className="truncate text-sm font-semibold leading-4 text-primary">
          @
          {post.influencer?.instagramUsername === null
            ? post.influencer?.tiktokUsername
            : post.influencer?.instagramUsername}
        </p>
        {post.likes > 0 && (
          <p className="flex flex-row text-xs font-semibold leading-4 text-zinc-400">
            <span role="img" aria-label="heart" className="mr-0.5">
              ❤️
            </span>
            {likeFormat(post.likes)}
          </p>
        )}
      </div>
      <div className="flex flex-row items-center justify-between">
        <p className="truncate text-base font-semibold leading-4 text-black">
          {post.influencer.fullName
            ? post.influencer.fullName
            : post?.influencer?.instagramUsername}
        </p>
        <p className="flex flex-row whitespace-nowrap text-xs font-semibold leading-4 text-zinc-400">
          <span role="img" aria-label="calendar" className="mr-0.5">
            📅
          </span>
          {dateFormat(post.publishDate)}
        </p>
      </div>
    </div>
  );
};

const FashionWeekItemDetails = ({ season, city, designer }) => {
  return (
    <div className="bg-white py-2 px-1 text-left">
      <p className="text-sm font-semibold leading-4 text-primary">
        {nameFormat(city)} Fashion Week {seasonFormat(season)}
      </p>
      <p
        className="truncate text-base font-semibold leading-6"
        title={nameFormat(designer)}
      >
        {nameFormat(designer)}
      </p>
    </div>
  );
};

export function Root({ data, children, moderationEnabled = true, isCarousel }) {
  const [ref, { width }] = useElementSize();

  const [hovered, setHovered] = useState(false);
  const [showAddModel, setShowAddModel] = useState(false);
  const [containerHeight, setContainerHeight] = useState(false);

  function closeAll() {
    setHovered(false);
    setShowAddModel(false);
  }

  function handleHoverState(visible) {
    if (!visible) closeAll();

    setHovered(visible);
  }

  return (
    <CardContext.Provider
      value={{
        containerWidth: width,
        setContainerHeight,
        hovered,
        showAddModel,
        setShowAddModel,
        handleHoverState,
        isCarousel,
        data,
      }}
    >
      <div
        className={cn(
          data.disabled ? styles.rootDisabled : 'group/explore-card-root',
        )}
      >
        <div
          className={styles.root}
          ref={ref}
          onMouseEnter={() => handleHoverState(true)}
          onMouseLeave={() => handleHoverState(false)}
          style={containerHeight ? { height: containerHeight + 'px' } : {}}
        >
          <div
            onClick={(e) => {
              if (hovered) {
                e.stopPropagation();
              }
            }}
          >
            {children}
          </div>
        </div>
        {data.post && <SocialMediaItemDetails post={data.post} />}
        {data.influencer && <SocialMediaItemDetails post={data} />}
        {moderationEnabled && <Moderation apparelId={data.id} />}
        {data.season && data.city && data.designer && (
          <FashionWeekItemDetails
            season={data.season}
            city={data.city}
            designer={data.designer}
          />
        )}
      </div>
    </CardContext.Provider>
  );
}
