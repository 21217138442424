import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GENERATE_STUDIO_ITEM,
  STUDIO_GENERATE_PATTERN,
  STUDIO_IMAGE_UPSCALE,
  STUDIO_REALIZE_SKETCH,
  STUDIO_TASK,
} from '@/api/studio/mutations';
import { GET_SIMILAR_IMAGES } from '@/api/studio/queries';
import { deepEqual } from '@/helpers/utils';
import {
  conceptOptions,
  orientationOptions,
} from '@/pages/general/studio/components/options';

export const StudioContext = createContext();

const defaultConcept = conceptOptions[0];
const defaultOrientation = orientationOptions[0];

export function StudioProvider({ children }) {
  const [createItem, setCreateItem] = useState({
    icon: null,
    text: null,
    type: null,
  });
  const [referenceImage, setReferenceImage] = useState(null);
  const [referencePattern, setReferencePattern] = useState(null);
  const [referenceError, setReferenceError] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [selectedTool, setSelectedTool] = useState('');
  const [generateSize, setGenerateSize] = useState('Portrait');
  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [generateVisibility, setGenerateVisibility] = useState('PUBLIC');
  const [generateType, setGenerateType] = useState('templates');
  const [activeModal, setActiveModal] = useState(null);
  const [isHome, setIsHome] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTile, setIsTile] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [orientation, setOrientation] = useState('portrait');
  const [fetchedId, setFetchedId] = useState(null);
  const [selectedGeneratedImage, setSelectedGeneratedImage] = useState(0);

  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    item: null,
    design: null,
    concept: defaultConcept,
    orientation: defaultOrientation,
    mode: 'GUIDED',
  });

  const [newPayload, setNewPayload] = useState({
    mode: 'EXPERT',
    positivePrompt,
    negativePrompt,
    visibility: generateVisibility,
    referenceImages: referenceImage
      ? [{ entityType: 'FILE_UPLOAD', entityId: referenceImage.id }]
      : [],
    referencePatterns: referencePattern
      ? [{ entityType: 'FILE_UPLOAD', entityId: referencePattern.id }]
      : [],
    orientation: {
      label: generateSize,
      value: generateSize.toUpperCase(),
    },
  });

  const [designTask, setDesignTask] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'get-inspired',
  });

  const [imagePopup, setImagePopup] = useState({
    isOpen: false,
    designTask: null,
  });

  const handleTitleIconChanges = (icon, text, type) => {
    setCreateItem({ icon, text, type });
  };

  const activeTab = searchParams.get('tab') || 'get-inspired';
  const setActiveTab = (tab) => {
    setSearchParams({ tab });
  };

  const [
    generateStudioItem, // create an apparel
    { data: generateStudioItemData, error: generateStudioItemError },
  ] = useMutation(GENERATE_STUDIO_ITEM);

  const [
    studioGeneratePattern, // create a pattern
    { data: generateStudioPatternData, error: generateStudioPatternError },
  ] = useMutation(STUDIO_GENERATE_PATTERN);

  const [
    realizeStudioSketch,
    { data: realizeStudioSketchData, error: realizeStudioSketchError },
  ] = useMutation(STUDIO_REALIZE_SKETCH);

  const [imageUpscale] = useMutation(STUDIO_IMAGE_UPSCALE);

  const [checkStudioTask] = useMutation(STUDIO_TASK);

  const [
    studioSimilarImages,
    { data: similarData, loading: similarLoading, error: similarError },
  ] = useLazyQuery(GET_SIMILAR_IMAGES);

  const updateDesignTask = (newFields) => {
    setDesignTask((prevDesignTask) => ({
      ...prevDesignTask,
      ...newFields,
    }));
  };

  const checkJobStatus = async (jobId) => {
    const { data } = await checkStudioTask({
      variables: {
        id: jobId,
      },
    });

    if (data?.studioTask?.status === 'COMPLETED') {
      updateDesignTask({
        payload: newPayload,
        loading: false,
        result: data.studioTask,
        ...data.studioTask,
      });
      return true;
    }

    return false;
  };
  useEffect(() => {
    let interval;

    const handleMutation = (mutationData, mutationType) => {
      if (mutationData) {
        const jobId = mutationData[mutationType].id;

        const newDesignTask = {
          payload,
          id: jobId,
        };

        if (!deepEqual(newDesignTask, designTask)) {
          updateDesignTask(newDesignTask);
        }

        interval = setInterval(async () => {
          try {
            const isCompleted = await checkJobStatus(jobId);
            if (isCompleted) {
              clearInterval(interval);
            }
          } catch (error) {
            console.error(`Error checking job status: ${error.message}`);
            handleError(error, mutationType);
          }
        }, 5000);
      }
    };

    const handleError = (error, errorType) => {
      console.error(`${errorType} error:`, error);
      clearInterval(interval);
      updateDesignTask({
        loading: false,
        error: {
          type: errorType,
          message: error.message,
        },
      });
      setIsHome(true);
    };

    if (generateStudioItemData) {
      handleMutation(generateStudioItemData, 'generateStudioItem');
    } else if (generateStudioPatternData) {
      handleMutation(generateStudioPatternData, 'studioGeneratePattern');
    } else if (realizeStudioSketchData) {
      handleMutation(realizeStudioSketchData, 'realizeStudioSketch');
    }

    if (generateStudioItemError) {
      handleError(generateStudioItemError, 'generateStudioItem');
    } else if (generateStudioPatternError) {
      handleError(generateStudioPatternError, 'studioGeneratePattern');
    } else if (realizeStudioSketchError) {
      handleError(realizeStudioSketchError, 'realizeStudioSketch');
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    generateStudioItemData,
    generateStudioPatternData,
    realizeStudioSketchData,
    generateStudioItemError,
    generateStudioPatternError,
    realizeStudioSketchError,
  ]);

  useEffect(() => {
    const handleError = (error, errorType) => {
      updateDesignTask({
        loading: false,
        error: {
          type: errorType,
          message: error.message,
        },
      });
    };

    if (generateStudioItemError) {
      handleError(generateStudioItemError, 'generateStudioItem');
    } else if (generateStudioPatternError) {
      handleError(generateStudioPatternError, 'generateStudioPattern');
    } else if (realizeStudioSketchError) {
      handleError(realizeStudioSketchError, 'realizeStudioSketch');
    }
  }, [
    generateStudioItemError,
    generateStudioPatternError,
    realizeStudioSketchError,
  ]);

  const clearDesignTask = () => {
    setPositivePrompt('');
    setNegativePrompt('');
    setReferenceImage(null);
    setReferencePattern(null);
    setReferenceError(false);
    setGenerateVisibility('PUBLIC');
    setGenerateSize('Square');
  };

  const handleGenerate = () => {
    if (!payload.item && payload?.mode === 'GUIDED') {
      return;
    }

    if (!payload?.positivePrompt && payload?.mode === 'EXPERT') {
      return;
    }

    setDesignTask({
      payload,
      loading: true,
      startedAt: new Date(),
    });

    setActiveTab('design-task');

    const backendPayload = JSON.parse(JSON.stringify(payload));
    if (backendPayload?.color) {
      delete backendPayload.color.hex;
    }

    generateStudioItem({
      variables: {
        payload: backendPayload,
      },
    });
  };

  const handleEditTask = (type, taskId) => {
    setDesignTask({
      payload: newPayload,
      loading: true,
      startedAt: new Date(),
    });

    if (type === 'imageUpscale') {
      setNewPayload({
        id: taskId,
        positivePrompt,
        negativePrompt,
        priority: 'FAST',
        upscaleValue: '2.0',
      });

      const variables = { payload: newPayload };

      if (type === 'imageUpscale') {
        imageUpscale({ variables });
      } else {
        console.error('Invalid task type');
      }
    }
  };

  const handleCreateTask = (type) => {
    setIsGenerating(true);
    if (type === 'sketch' && !referenceImage) {
      setReferenceError('Please upload a reference image');
      return;
    }

    if (type === 'pattern') {
      setNewPayload({
        positivePrompt,
        negativePrompt,
        priority: 'FAST',
        referencePatterns: referencePattern ? [referencePattern] : [],
      });
    } else {
      setNewPayload({
        ...newPayload,
        priority: 'FAST',
        referenceImages: referenceImage ? [referenceImage] : [],
        referencePatterns: referencePattern ? [referencePattern] : [],
      });
    }

    setDesignTask({
      payload: newPayload,
      loading: true,
      startedAt: new Date(),
    });

    const variables = {
      payload: {
        ...newPayload,
        referenceImages: referenceImage ? [referenceImage] : [],
        referencePatterns: referencePattern ? [referencePattern] : [],
      },
    };

    const typeActionMap = {
      apparel: generateStudioItem,
      pattern: studioGeneratePattern,
      sketch: realizeStudioSketch,
    };

    const action = typeActionMap[type];
    if (action) {
      action({
        variables,
        onCompleted: (data) => {
          const taskId = Object.values(data)[0]?.id;
          navigate(`/studio?taskId=${taskId}`);
        },
      });
      studioSimilarImages({
        variables: {
          payload: newPayload,
          generationType:
            action === realizeStudioSketch
              ? 'SKETCH'
              : action === studioGeneratePattern
                ? 'PATTERN'
                : 'APPAREL',
        },
      });
    }
  };

  const handleCreateApparel = () => handleCreateTask('apparel');
  const handleCreatePattern = () => handleCreateTask('pattern');
  const handleCreateFromSketch = () => handleCreateTask('sketch');
  const handleImageUpscale = () => handleEditTask('imageUpscale', fetchedId);

  return (
    <StudioContext.Provider
      value={{
        payload,
        setPayload,
        designTask,
        setDesignTask,
        activeTab,
        setActiveTab,
        handleGenerate,
        imagePopup,
        setImagePopup,

        handleCreateApparel,
        handleCreatePattern,
        handleCreateFromSketch,
        studioSimilarImages,
        similarData,
        similarLoading,
        similarError,
        handleTitleIconChanges,
        createItem,
        setCreateItem,

        setNewPayload,
        referenceImage,
        setReferenceImage,
        referencePattern,
        setReferencePattern,
        referenceError,
        setReferenceError,
        openTools,
        setOpenTools,
        selectedTool,
        setSelectedTool,
        generateSize,
        setGenerateSize,
        positivePrompt,
        setPositivePrompt,
        negativePrompt,
        setNegativePrompt,
        generateVisibility,
        setGenerateVisibility,

        activeModal,
        setActiveModal,
        orientation,
        setOrientation,
        generatedImages,
        setGeneratedImages,
        isGenerating,
        setIsGenerating,
        isHome,
        setIsHome,
        isTile,
        setIsTile,
        isPreview,
        setIsPreview,
        generateType,
        setGenerateType,

        handleImageUpscale,
        fetchedId,
        setFetchedId,
        selectedGeneratedImage,
        setSelectedGeneratedImage,
        updateDesignTask,
        clearDesignTask,
      }}
    >
      {children}
    </StudioContext.Provider>
  );
}

export const useStudioContext = () => useContext(StudioContext);
