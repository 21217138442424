import * as React from 'react';

import { cn } from '@/helpers/utils.js';

const Textarea = React.forwardRef(
  ({ className, variant, onClick, onIconClick, icon: Icon, ...props }, ref) => {
    if (variant === 'studio') {
      return (
        <div className="relative">
          <textarea
            className={cn(
              'block min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50',
              className,
            )}
            ref={ref}
            {...props}
            onClick={onClick}
          />
          <div
            className="absolute inset-y-px right-px rounded-l-md pr-1 pt-1"
            onClick={onIconClick}
          >
            <Icon
              className="focus:outline-none0 inline-flex shrink-0 cursor-pointer items-center justify-center rounded p-0.5 text-charcoal hover:bg-white hover:text-primary"
              size={22}
            />
          </div>
        </div>
      );
    }

    return (
      <textarea
        className={cn(
          'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        onClick={onClick}
        {...props}
      />
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
