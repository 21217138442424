import { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useLazyQuery, useMutation } from '@apollo/client';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import {
  DiamondPlus,
  Eraser,
  Fullscreen,
  ImagePlus,
  Palette,
  Pen,
  RefreshCw,
  Replace,
  ScanSearch,
  SquareAsterisk,
  Trash,
} from 'lucide-react';

import { UPDATE_FILE_STATUS } from '@/api/studio/mutations';
import { GENERATE_FILE_UPLOAD_URL } from '@/api/studio/queries';
import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton, SidebarTitle } from './SidebarMenu';

const tools = [
  { text: 'Variants', icon: DiamondPlus, value: 'variants' },
  { text: 'Upscale', icon: Replace, value: 'upscale' },
  { text: 'RemoveBg', icon: Eraser, value: 'removeBg' },
  { text: 'Pattern', icon: SquareAsterisk, value: 'pattern' },
  { text: 'Zoom in', icon: Fullscreen, value: 'zoom-in' },
  { text: 'Zoom out', icon: ScanSearch, value: 'zoom-out' },
  { text: 'Color Tone', icon: Palette, value: 'color-tone' },
  { text: 'Inpainting', icon: ImagePlus, value: 'inpainting' },
  { text: 'Img Manip.', icon: ImagePlus, value: 'imgManipulation' },
  { text: 'Bg Manip.', icon: Pen, value: 'bgManipulation' },
];

export const StudioToolsGrid = () => {
  const { selectedTool, setSelectedTool } = useStudioContext();

  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-6">
      {tools.map((tool) => (
        <SidebarButton
          key={tool.value}
          text={tool.text}
          icon={tool.icon}
          variant="tool"
          active={selectedTool === tool.value}
          onClick={() => setSelectedTool(tool.value)}
        />
      ))}
    </div>
  );
};

TimeAgo.addDefaultLocale(en);

export const GenerationElapsed = () => {
  const { designTask } = useStudioContext();
  return (
    <span className={'tabular-nums'} style={{ fontSize: 14, color: '#a1a1a1' }}>
      <ReactTimeAgo
        date={designTask?.startedAt}
        timeStyle={{
          steps: [
            {
              formatAs: 'second',
            },
          ],
          labels: 'mini',
        }}
        tooltip={false}
      />
    </span>
  );
};

export const UploadBox = ({
  title,
  icon: Icon,
  text,
  type,
  disabled = false,
  fileVisibility = 'PRIVATE',
  fileEntityType = 'APPAREL',
  editImage = null,
}) => {
  const [generateFileUploadUrl] = useLazyQuery(GENERATE_FILE_UPLOAD_URL);
  const [updateFileStatus] = useMutation(UPDATE_FILE_STATUS);
  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const {
    setReferenceImage,
    setReferencePattern,
    setReferenceError,
    referenceError,
  } = useStudioContext();

  useEffect(() => {
    if (editImage && editImage.url && editImage.type) {
      if (editImage.type === 'pattern') {
        setReferencePattern({
          entityId: editImage.entityId,
          entityType: editImage.entityType,
        });
      } else if (editImage.type === 'image') {
        setReferenceImage({
          entityId: editImage.entityId,
          entityType: editImage.entityType,
        });
      }
      setUploadedImage(editImage);
    }
  }, [editImage?.id]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  useEffect(() => {
    if (referenceError) {
      setTimeout(() => {
        setReferenceError(null);
      }, 4000);
    }
  }, [referenceError]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      setReferenceError('No file selected');
      return;
    }

    try {
      setUploading(true);
      setReferenceError(null);

      const { data, error: queryError } = await generateFileUploadUrl({
        variables: {
          fileVisibility,
          fileName: file.name,
          mimeType: file.type,
          fileEntityType,
        },
      });

      if (queryError) {
        if (queryError.message === 'FILE_UPLOAD_IN_PROGRESS') {
          setReferenceError('This file is already being uploaded');
        } else {
          setReferenceError('This file cannot be uploaded');
        }
      }

      if (data?.generateFileUploadUrl?.signedUrl) {
        const response = await fetch(data.generateFileUploadUrl.signedUrl, {
          method: 'PUT',
          body: file,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        if (response.status === 200) {
          await updateFileStatus({
            variables: {
              fileId: data.generateFileUploadUrl.entityId,
              status: 'DONE',
            },
          });

          const imageUrl = URL.createObjectURL(file);

          setUploadedImage({
            id: data.generateFileUploadUrl.entityId,
            name: file.name,
            type: type === 'image' ? 'image' : 'pattern',
            url: imageUrl,
          });

          if (type === 'image') {
            setReferenceImage({
              entityId: data.generateFileUploadUrl.entityId,
              entityType: 'FILE_UPLOAD',
            });
          }
          if (type === 'pattern') {
            setReferencePattern({
              entityId: data.generateFileUploadUrl.entityId,
              entityType: 'FILE_UPLOAD',
            });
          }
        }
      } else {
        throw new Error('Failed to get signed URL');
      }
    } catch (error) {
      console.error(error);
      setReferenceError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    setReferenceError(false);
  };

  if (disabled) {
    return (
      <div className="flex flex-col gap-2">
        <SidebarTitle text={title} />
        <label className="flex cursor-not-allowed flex-col items-center justify-center gap-y-2 rounded border border-night/10 bg-secondary/20 p-5 text-center text-sm text-night">
          <Icon size={16} />
          {text}
        </label>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <SidebarTitle text={title} />
      {uploadedImage ? (
        <div className="group relative">
          <img
            src={uploadedImage.url}
            alt={uploadedImage.name}
            className="h-auto w-full max-w-[200px] rounded"
          />
          <div className="absolute inset-0 bg-night/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
          <div className="absolute top-2 right-2 flex gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <label className="cursor-pointer rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
              <RefreshCw size={20} />
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                accept="image/*"
              />
            </label>
            <button
              className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-red-600 hover:bg-white hover:text-red-600"
              onClick={handleDeleteImage}
            >
              <Trash size={20} />
            </button>
          </div>
        </div>
      ) : (
        <label
          className={`focus-visible:none flex cursor-pointer flex-col items-center justify-center gap-y-2 rounded border ${
            isDragging
              ? 'border-primary bg-[#FFFDFD]'
              : 'border-night/5 bg-secondary/5'
          } p-5 text-center text-sm text-night hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept="image/*"
            disabled={uploading}
          />
          <Icon size={16} />
          <p>{uploading ? 'Uploading...' : text}</p>
        </label>
      )}
    </div>
  );
};
