import React, { useState } from 'react';
import { DiamondPlus, Grip, Replace } from 'lucide-react';

import { LikeButton } from '@/components/LikeButton';
import { useStudioContext } from '@/contexts/StudioContext';
import { SaveToCollection } from '@/layouts/common/index';

import StudioImage from './StudioImage';

export const ImagePreview = ({ images, activeIndex, setActiveIndex }) => {
  const [activeImage, setActiveImage] = useState(images[activeIndex]);

  return (
    <div className="flex gap-4">
      <div className="flex w-1/6 flex-col gap-4">
        {images.map((image, index) => (
          <TileImage
            key={index}
            imageUrl={image.url}
            variant="preview"
            isActive={activeImage === images[index]}
            onClick={() => {
              setActiveIndex(index);
              setActiveImage(images[index]);
            }}
          />
        ))}
      </div>
      <div className="w-5/6">
        <StudioImage
          id={activeImage.id}
          image={activeImage}
          selectedAreaType="square"
          onChangeSelectedArea={(box) => {
            console.log(box);
          }}
        />
      </div>
    </div>
  );
};

export const TileImage = ({
  imageUrl,
  itemId,
  title,
  description,
  alt,
  aspect = 'square',
  variant = 'default',
  isActive = false,
  onClick,
}) => {
  const { openTools, setOpenTools, setSelectedTool } = useStudioContext();

  const handleClick = (type) => {
    if (type === 'variants') {
      setSelectedTool('variants');
      setOpenTools(true);
    }
    if (type === 'upscale') {
      setSelectedTool('upscale');
      setOpenTools(true);
    }
    if (type === 'tools') {
      setOpenTools(!openTools);
    }
    if (onClick) {
      onClick();
    }
  };

  if (variant === 'preview') {
    return (
      <div
        className={`relative cursor-pointer overflow-hidden rounded`}
        onClick={onClick}
      >
        <img src={imageUrl} alt={alt} className="h-auto w-full object-cover" />
        {isActive && <div className="absolute inset-0 bg-night/60"></div>}
      </div>
    );
  }
  return (
    <div
      className="group relative w-full cursor-pointer overflow-hidden rounded"
      style={{ '--container-width': '100%' }}
    >
      <img
        src={imageUrl}
        alt={alt}
        className={`aspect-${aspect} h-full w-full scale-105 object-cover transition-all duration-300 group-hover:scale-110`}
      />
      <div
        className="absolute inset-0 bg-black/30 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        onClick={onClick}
      />
      <div className="absolute top-[5%] right-[5%] flex space-y-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex flex-col gap-2">
          <LikeButton item={itemId} isLiked={false} variant="icon" />
          <SaveToCollection id={itemId} type="studioItem" variant="icon" />
        </div>
      </div>
      <div className="absolute inset-x-[5%] bottom-[5%] flex justify-between opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <div className="flex w-full flex-col gap-2 sm:flex-row">
          <button
            onClick={() => handleClick('variants')}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-[2%] py-[1%] text-center text-[0.875rem] font-bold leading-[1.3rem] text-black hover:border-primary hover:bg-white hover:text-primary"
          >
            <DiamondPlus size={16} />
            Variants
          </button>
          <button
            onClick={() => handleClick('upscale')}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-[2%] py-[1%] text-center text-[0.875rem] font-bold leading-[1.3rem] text-black hover:border-primary hover:bg-white hover:text-primary"
          >
            <Replace size={16} />
            Upscale
          </button>
          <button
            onClick={() => handleClick('tools')}
            className="flex w-full items-center justify-center gap-2 rounded border border-secondary/10 bg-white px-[2%] py-[1%] text-center text-[0.875rem] font-bold leading-[1.3rem] text-black hover:border-primary hover:bg-white hover:text-primary"
          >
            <Grip size={16} />
            Tools
          </button>
        </div>
      </div>
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full px-[3%] py-[2%]">
          {title && (
            <h3
              className="font-bold leading-5 text-white"
              style={{ fontSize: 'calc(var(--container-width) * 0.04)' }}
            >
              {title}
            </h3>
          )}
          {description && (
            <p
              className="mt-1 text-white"
              style={{ fontSize: 'calc(var(--container-width) * 0.03)' }}
            >
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const ImageView = ({
  imageUrl,
  hoverImageUrl,
  title,
  description,
  alt,
  aspect = 'square',
}) => {
  return (
    <div className="group relative w-full cursor-pointer overflow-hidden rounded">
      <img
        src={imageUrl}
        alt={alt}
        className={`aspect-${aspect} h-full w-full scale-105 object-cover transition-all duration-300 group-hover:opacity-0`}
      />
      <img
        src={hoverImageUrl || imageUrl}
        alt={alt}
        className={`aspect-${aspect} absolute top-0 left-0 h-full w-full scale-105 object-cover opacity-0 transition-opacity duration-300 group-hover:opacity-100`}
      />
      {(title || description) && (
        <div className="absolute bottom-0 left-0 w-full translate-y-full px-4 py-2 transition-transform duration-300 group-hover:translate-y-0">
          {title && (
            <h3 className="text-base font-bold leading-5 text-white">
              {title}
            </h3>
          )}
          {description && (
            <p className="mt-1 text-sm text-white">{description}</p>
          )}
        </div>
      )}
      {title && (
        <div className="absolute bottom-2 left-4 text-white group-hover:opacity-0">
          <h3 className="text-base font-bold">{title}</h3>
        </div>
      )}
    </div>
  );
};
