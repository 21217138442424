import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useImageSize } from 'react-image-size';
import { Link, useParams } from 'react-router-dom';
import { camelCase } from 'lodash';
import numeral from 'numeral';

import { useUser } from '@/api/authentication/hook';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useTrendsContext } from '@/contexts/TrendsContext';

function TrendsCardImage({ i, imageInfo: image }) {
  const [loader, setLoader] = useState(true);

  const item = image ? { ...image } : {};
  const [dimensions, { loading, error }] = useImageSize(item.url);

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = item.url;

    imageToLoad.onload = () => {
      setLoader(false);
    };
    return () => setLoader(false);
  }, [item.url]);

  if (error) return <div></div>;
  if (loading) return <div></div>;

  if (!item || !dimensions) return <></>;

  if (!item?.width) item.width = dimensions.width;
  if (!item?.height) item.height = dimensions.height;

  return (
    <>
      {loader ? (
        <div className="flex h-full flex-row justify-items-start rounded-xl">
          {item.blurhash && (
            <Blurhash
              hash={item.blurhash}
              width="100%"
              height="160px"
              punch={1}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-wrap justify-items-start rounded-xl">
          <div
            style={{
              background: `url(${image.url})` || '#111',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: '18 / 25',
              width: '100%',
            }}
            className={`h-full ${i === 0 ? 'rounded-l-xl' : ''} ${
              i === 2 ? 'rounded-r-xl' : ''
            }`}
          />
        </div>
      )}
    </>
  );
}

export const TrendTonesCard = ({ variant, trendItem: item, tab }) => {
  const { selectedSeason, selectedAudience } = useAppFilters();
  const { activeTab } = useTrendsContext();

  const { id, title, trendItemId, marketId } = item;

  const itemUrl = `/trends/${activeTab}/detail/${
    selectedAudience?.marketId || marketId
  }/${encodeURIComponent(title.toLowerCase().replaceAll(' ', '-'))}/${
    trendItemId || id
  }/${selectedSeason?.value || '0'}`;

  const images = (item?.featuredImages || []).slice(0, 3);
  const variantMap = {
    sm: 'w-64',
    md: 'w-full',
  };

  const yoyGrowth = item?.yoyGrowth?.percent.toFixed(0);
  const colorStyle = yoyGrowth > 0 ? { color: '#1C9A6D' } : { color: 'red' };
  const sign = yoyGrowth > 0 ? '+' : '';

  return (
    <div className={`flex flex-col rounded-xl ${variantMap[variant]}`}>
      <Link to={itemUrl}>
        <div className="flex aspect-[2.15] w-full gap-[0.05rem] rounded-xl shadow-md [&>*]:w-1/3">
          {images.map((image, index) => (
            <TrendsCardImage key={index} i={index} imageInfo={image} />
          ))}
        </div>
        <div className="ml-[0.625rem] flex flex-col">
          <h3 className="mt-[1rem] text-[1.5rem] font-semibold leading-[1.625rem]">
            {item?.title}
          </h3>
          <p className="text-[1rem] leading-6">
            {tab === 'snapshots' ? 'Visibility ' : 'Year over Year Growth '}
            <span style={colorStyle}>
              {sign}
              {yoyGrowth}%
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export const TrendsCard = ({ variant, trendItem: item }) => {
  const { selectedSeason } = useAppFilters();
  const { activeTab } = useTrendsContext();

  const { isAnnotator } = useUser();

  const { marketId } = useParams();

  const { id, title, trendItemId } = item;

  const itemUrl = `/trends/${activeTab}/detail/${marketId}/${encodeURIComponent(title.toLowerCase().replaceAll(' ', '-'))}/${
    trendItemId || id
  }/${selectedSeason?.value || '0'}`;

  const images = (item.featuredImages || []).slice(0, 3);

  const yoyGrowth = item?.yoyGrowth?.percent.toFixed(0);
  const colorStyle = yoyGrowth > 0 ? { color: '#1C9A6D' } : { color: 'red' };
  const sign = yoyGrowth > 0 ? '+' : '';

  return (
    <div className="flex w-full flex-col rounded-xl">
      <Link to={itemUrl}>
        <div className="flex aspect-[2.15] w-full gap-[0.05rem] rounded-xl shadow-md [&>*]:w-1/3">
          {images.map((image, index) => (
            <TrendsCardImage key={index} i={index} imageInfo={image} />
          ))}
        </div>
        <div className="ml-[0.625rem] flex flex-col">
          <h3
            className={`mt-[1rem] mb-1.5 ${variant === 'sm' ? 'text-[1.375rem] leading-[1.375rem]' : 'text-[1.5rem] leading-[1.625rem]'} font-semibold `}
          >
            {isAnnotator ? (
              item.image?.source?.id !== 'AUTOMATIC' ? (
                <span className="mr-2 rounded-full bg-red-500 p-1 px-2 text-sm text-white">
                  Edited
                </span>
              ) : (
                <span className="mr-2 rounded-full bg-emerald-400 px-2 text-sm text-white"></span>
              )
            ) : null}
            {item?.title || 'Similar Apparel'}
          </h3>
          <p
            className={`${variant === 'sm' ? 'text-base leading-5' : 'text-[1.125rem] leading-6'}`}
          >
            {'Year over Year Growth '}
            <span style={colorStyle}>
              {sign}
              {yoyGrowth}%
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export const SnapsCard = ({ variant, snapshotItem: item }) => {
  const { isAnnotator } = useUser();
  const { activeTab } = useTrendsContext();

  const { selectedTimeframe, selectedSnapshotSort } = useAppFilters();

  const title = item.title;
  const snapshotItemId = item.id;

  const snapshotsUrl = `/trends/${activeTab}/detail/${encodeURIComponent(title?.toLowerCase().replaceAll(' ', '-'))}/${snapshotItemId}`;

  const images = (item.featuredImages || []).slice(0, 3);

  const filterSort =
    selectedSnapshotSort === 'PostScore' || selectedSnapshotSort === null
      ? 'QualityEngagement'
      : selectedSnapshotSort;

  const queryVar = `${camelCase(selectedTimeframe)}${filterSort}`;

  const getEngagementValue = (value) => {
    if (filterSort === 'Magnitude') {
      const magnitudeMap = {
        1: 'Micro',
        2: 'Medium',
        3: 'Macro',
      };
      return (
        magnitudeMap[value] || value.charAt(0).toUpperCase() + value.slice(1)
      );
    }

    if (Number.isInteger(value)) {
      return numeral(value).format('0a').toUpperCase();
    }

    if (typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return numeral(value).format('0.0a').toUpperCase();
  };

  const engagement = getEngagementValue(item[queryVar]);

  const colorStyle = { color: '#1C9A6D' };

  return (
    <div className="relative flex w-full flex-col rounded-xl">
      <Link to={snapshotsUrl}>
        <div className="flex aspect-[2.15] w-full gap-[0.07rem] rounded-xl shadow-md [&>*]:w-1/3">
          {images.map((image, index) => (
            <TrendsCardImage key={index} i={index} imageInfo={image} />
          ))}
        </div>
        <div className="ml-[0.625rem] flex flex-col">
          <h3
            className={`mt-[1rem] mb-1.5 capitalize ${variant === 'sm' ? 'text-[1.375rem] leading-[1.375rem]' : 'text-[1.5rem] leading-[1.625rem]'} font-semibold `}
          >
            {isAnnotator ? (
              item.image?.source?.id !== 'AUTOMATIC' ? (
                <span className="mr-2 rounded-full bg-red-500 p-1 px-2 text-sm text-white">
                  Edited
                </span>
              ) : (
                <span className="mr-2 rounded-full bg-emerald-400 px-2 text-sm text-white"></span>
              )
            ) : null}
            {item?.title.charAt(0) === item?.title.charAt(0).toUpperCase()
              ? item.title
              : item.title
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
          </h3>
          <p
            className={`${variant === 'sm' ? 'text-base leading-5' : 'text-[1.125rem] leading-6'}`}
          >
            {selectedSnapshotSort === 'Magnitude'
              ? 'Magnitude: '
              : 'Quality Engagement: '}
            <span style={colorStyle}>{engagement}</span>
          </p>
        </div>
      </Link>
    </div>
  );
};
