import { useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useQuery } from '@apollo/client';

import { EXPLORE_FASHION_ITEMS } from '@/api/explore/queries';
import { FlexRow } from '@/components';
import { LikeButton } from '@/components/LikeButton';
import {
  returnFashionItemFilters,
  useExploreContext,
} from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { SaveToCollection } from '@/layouts/common/index';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card, More } from './components';

function FashionItems() {
  const { fashionWeekFilters: filters, activeTab } = useExploreContext();

  const { data, networkStatus, loading, fetchMore, error, refetch } = useQuery(
    EXPLORE_FASHION_ITEMS,
    {
      variables: {
        filters: returnFashionItemFilters(filters),
        search: filters.search?.value?.value,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    refetch({
      filters: returnFashionItemFilters(filters),
    });
  }, [filters]);

  const loadingMore = networkStatus === 3;

  if (loading && !loadingMore) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const { edges, pageInfo } = data?.exploreFashionWeekItems;
  const { endCursor, hasNextPage } = pageInfo;

  return (
    <>
      <div>
        {edges?.length === 0 && (
          <div>
            <NoSuchResultsWereFound />
          </div>
        )}
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 2,
            1024: 3,
            1280: 4,
            1600: 5,
            1920: 6,
          }}
        >
          <Masonry gutter={'1.625rem'}>
            {edges?.map(({ node: exploreItem }, index) => (
              <Card.Root
                data={exploreItem}
                key={'explore_' + index}
                moderationEnabled={false}
              >
                <Card.Image />

                <Card.HoverContent>
                  <FlexRow justify="between" items="center" className="-mr-12">
                    <Card.Tones />

                    <FlexRow
                      className="self-end"
                      justify="between"
                      items="center"
                    >
                      <SaveToCollection id={exploreItem.id} type="fashionItem">
                        <div className="bg-white py-1 text-center">
                          <p className="text-base font-semibold">Save</p>
                        </div>
                      </SaveToCollection>

                      <LikeButton
                        item={exploreItem.id}
                        isLiked={exploreItem.isLiked}
                        type="fashionItem"
                      />

                      <More />
                    </FlexRow>
                  </FlexRow>

                  <Card.Footer
                    id={exploreItem.id}
                    exploreItemId={exploreItem.photoItem}
                    activeTab={activeTab}
                  />
                </Card.HoverContent>
              </Card.Root>
            ))}

            {loadingMore &&
              Array.from({ length: 20 }).map((_, i) => (
                <div className="ghost aspect-[2/3] w-full" key={i}></div>
              ))}

            {hasNextPage &&
              !loadingMore &&
              Array(20)
                .fill()
                .map((_, index) => (
                  <LazyLoadTrigger
                    key={'lazyload_' + index}
                    fetchMore={() => {
                      fetchMore({
                        variables: {
                          cursor: endCursor,
                        },
                      });
                    }}
                    cursorNext={endCursor}
                    loading={loading}
                  />
                ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
}

export function FashionWeekItems() {
  return (
    <FashionWeeksProvider>
      <FashionItems />
    </FashionWeeksProvider>
  );
}
