import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useFiltersQuery } from '@/api/filters/hook';
import { useTrendsQuery } from '@/api/trends/hook';
import { useAppFilters } from '@/contexts/AppFiltersContext';

dayjs.extend(utc);

export const Context = createContext();

export function TrendsProvider({ children }) {
  const {
    filters,
    selectedTrendsColors,
    selectedTrendsCategory,
    selectedSeason,
    selectedAudience,
    selectedSorting,
  } = useAppFilters();

  const { audienceId } = useParams();

  const params = useParams();

  const [forecastYear, setForecastYear] = useState(dayjs.utc().year());
  const [availableYears, setAvailableYears] = useState([]);
  const [trendItemId, setTrendItemId] = useState(null);
  const [activeTab, setActiveTab] = useState(params.tab || 'snapshots');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [cursorNext, setCursorNext] = useState(null);
  const [tonesCursorNext, setTonesCursorNext] = useState(null);
  const [activeChart, setActiveChart] = useState('visibility');

  const audienceFilter = selectedAudience
    ? [selectedAudience.value]
    : audienceId
      ? [audienceId]
      : null;

  useEffect(() => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  }, [filters]);

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: {
        ...prevFilters[filterName],
        value,
      },
    }));
  };

  const pastButtonDisabled = availableYears[0] === forecastYear;
  const forecastButtonDisabled =
    availableYears[availableYears.length - 1] === forecastYear;

  function prevYear() {
    setForecastYear((prev) => prev - 1);
  }

  function nextYear() {
    setForecastYear((prev) => prev + 1);
  }

  function setYear(year) {
    if (!availableYears.includes(year)) {
      return;
    }

    setForecastYear(year);
  }

  const queryVariables = useMemo(
    () => ({
      cursor: null,
      filters: {
        pattern: null,
        color: selectedTrendsColors?.length
          ? selectedTrendsColors.map((c) => c.value)
          : null,
        apparel: selectedTrendsCategory?.length
          ? selectedTrendsCategory.map((a) => a.value.toString())
          : null,
      },
      audience: audienceFilter,
      season: selectedSeason?.value || '0',
      sorting: {
        sortBy: selectedSorting?.field || null,
        sortOrder: selectedSorting?.value || null,
      },
    }),
    [
      selectedTrendsColors,
      selectedTrendsCategory,
      selectedSeason,
      selectedSorting,
      audienceId,
      selectedAudience,
    ],
  );

  const {
    data: trendItemsData,
    loading: trendItemsLoading,
    error: trendItemsError,
    refetch: refetchTrendItems,
    fetchMore: fetchMoreTrendItems,
    networkStatus: trendItemsNetworkStatus,
  } = useTrendsQuery('trendItems', {
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    onCompleted: (data) => {
      if (data?.trendItems?.cursor) setCursorNext(data.trendItems.cursor);
    },
  });

  const { data: marketsData, loading: marketsLoading } =
    useFiltersQuery('markets');

  const {
    data: trendTonesData,
    loading: trendTonesLoading,
    error: trendTonesError,
    fetchMore: fetchMoreTrendTones,
    refetch: refetchTrendTones,
    networkStatus: trendTonesNetworkStatus,
  } = useTrendsQuery('trendTones', {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    onCompleted: (tonesData) => {
      if (tonesData?.tones?.cursor) setTonesCursorNext(tonesData.tones.cursor);
    },
  });

  useEffect(() => {
    if (selectedFilters.season && activeTab === 'snapshots') {
      refetchTrendItems(queryVariables);
      refetchTrendTones(queryVariables);
    }
  }, [
    selectedFilters.season,
    refetchTrendItems,
    refetchTrendTones,
    queryVariables,
  ]);

  return (
    <Context.Provider
      value={{
        forecastYear,
        setYear,
        availableYears,
        activeTab,
        setActiveTab,
        setAvailableYears,
        prevYear,
        nextYear,
        trendItemId,
        handleFilterChange,
        selectedFilters,
        setTrendItemId,
        pastButtonDisabled,
        forecastButtonDisabled,
        marketsData,
        marketsLoading,

        trendItemsData,
        trendItemsLoading,
        trendItemsError,
        fetchMoreTrendItems,
        trendItemsNetworkStatus,

        trendTonesData,
        trendTonesLoading,
        trendTonesError,
        fetchMoreTrendTones,
        trendTonesNetworkStatus,
        cursorNext,
        tonesCursorNext,

        activeChart,
        setActiveChart,

        selectedSocialWatch: selectedAudience?.socialWatch,

        audienceFilter,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useTrendsContext = () => useContext(Context);
