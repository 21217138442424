'use client';
import * as React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { cn } from '@/helpers/utils.js';

const ToggleGroupContext = React.createContext({
  size: 'default',
  variant: 'default',
});

const ToggleGroup = React.forwardRef(
  ({ className, variant, size, children, ...props }, ref) => (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={cn('flex items-center justify-center', className)}
      {...props}
    >
      <ToggleGroupContext.Provider value={{ variant, size }}>
        {children}
      </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
  ),
);

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef(
  ({ className, children, variant, size, active, ...props }, ref) => {
    const activeClass = active
      ? 'bg-[#FFFDFD] border-primary text-primary'
      : '';

    if (variant === 'studio') {
      return (
        <ToggleGroupPrimitive.Item
          ref={ref}
          className={cn(
            `w-full flex cursor-pointer items-center justify-center font-bold text-center text-night text-sm bg-secondary/10 border-night/5 focus-visible:none focus:ring-0 rounded p-2 border hover:bg-[#FFFDFD] hover:border-primary hover:text-primary disabled:hover:cursor-not-allowed disabled:border-night/5 disabled:text-night disabled:bg-secondary/10 focus:border-primary focus-visible:outline-none focus:bg-[#FFFDFD] focus:text-primary ${activeClass}`,
            className,
          )}
          {...props}
        >
          {children}
        </ToggleGroupPrimitive.Item>
      );
    }

    if (variant === 'nav') {
      return (
        <ToggleGroupPrimitive.Item
          ref={ref}
          className={cn(
            `w-full flex cursor-pointer items-center justify-center font-bold text-center text-secondary text-sm focus:ring-0 focus:outline-none focus:text-night pb-1 ${active ? 'text-night border-b-2 border-night' : 'text-secondary border-b-2 border-secondary hover:text-night'}`,
            className,
          )}
          {...props}
        >
          {children}
        </ToggleGroupPrimitive.Item>
      );
    }

    return (
      <ToggleGroupPrimitive.Item
        ref={ref}
        className={cn(
          `w-full [&[data-state="on"]]:bg-primary [&[data-state="on"]]:border-2 [&[data-state="on"]]:border-primary [&[data-state="on"]]:text-white bg-white text-primary border-2 border-primary font-medium rounded-none px-3 py-2 disabled:hover:cursor-not-allowed ${activeClass}`,
          className,
        )}
        {...props}
      >
        {children}
      </ToggleGroupPrimitive.Item>
    );
  },
);

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ToggleGroup, ToggleGroupItem };
