import { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { capitalize, startCase } from 'lodash';

import {
  EXPLORE_FASHION_WEEK_CITIES,
  EXPLORE_FASHION_WEEK_FILTERS,
} from '@/api/explore';
import {
  EXPLORE_SOCIALWATCH_FILTERS,
  GET_TYPEAHEAD_SUGGESTIONS,
} from '@/api/explore/queries';
import { GET_TREND_FILTERS } from '@/api/filters';
import { GET_MARKETS } from '@/api/filters/queries';
import { platforms, seasons } from '@/constants';
import { getAudienceLabel, getMarketLabel } from '@/contexts/AppFiltersContext';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { colorOptions } from '@/pages/general/explore/components/options';
import tones from '@/tones.json';

export const ExploreContext = createContext();

export function returnFashionItemFilters(filters) {
  let city = filters?.fashionWeek?.value?.value;
  if (city === 'all') city = null;

  const season = filters?.season?.value?.value;
  const designer = filters?.designer?.value?.value;
  const category = Array.isArray(filters?.category?.value)
    ? filters?.category?.value.map((i) => ({
        value: i?.value,
        items: [],
      }))
    : filters?.category?.value?.value
      ? [
          {
            value: filters?.category?.value?.value,
            items: [],
          },
        ]
      : undefined;

  const itemType = filters?.type?.value?.value;

  return {
    city,
    season,
    designer,
    apparels: category,
    itemType,
  };
}

export const ExploreProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('fashion-weeks');
  const [hasBannedApparel, setHasBannedApparel] = useState(false);
  // const [, ] = useSearchParams()

  const [fashionWeekFilters, setFashionWeekFilters] = useState(() => {
    // const storedFilters = localStorage.getItem('fashionWeekFilters');
    // if (storedFilters) {
    //   const parsedFilters = JSON.parse(storedFilters);
    //   if (parsedFilters && typeof parsedFilters === 'object') {
    //     return parsedFilters;
    //   }
    // }
    return {
      search: {
        placeholder: 'Search by term e.g. vintage',
        value: '',
        loading: false,
        isClearable: true,
        isSearchable: true,
        options: [],
      },
      fashionWeek: {
        label: 'Fashion Week',
        placeholder: 'Search for a fashion week',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
      season: {
        label: 'Season',
        placeholder: 'Select a season',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
      designer: {
        label: 'Designer',
        placeholder: 'Search a designer',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
      category: {
        label: 'Category',
        placeholder: 'Select a category',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
      type: {
        label: 'Type',
        placeholder: 'Select a type',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
    };
  });

  const [socialMediaFilters, setSocialMediaFilters] = useState(() => {
    // const storedFilters = localStorage.getItem('socialMediaFilters');
    // if (storedFilters) {
    //   const parsedFilters = JSON.parse(storedFilters);
    //   if (parsedFilters && typeof parsedFilters === 'object') {
    //     return parsedFilters;
    //   }
    // }
    return {
      search: {
        placeholder: 'Search by...',
        value: '',
        loading: false,
      },
      market: {
        label: 'Market',
        placeholder: 'Select a market',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
      platform: {
        label: 'Platform',
        placeholder: 'Select a social media platform',
        options: platforms,
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
        button: true,
      },
      timeframe: {
        label: 'Timeframe',
        placeholder: 'Select a timeframe',
        options: seasons,
        value: null,
        loading: false,
        isClearable: true,
      },
      category: {
        label: 'Category',
        placeholder: 'Select a category',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
      },
      'attribute.custom': {
        label: 'Design',
        placeholder: 'Select a design',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: true,
      },
      color: {
        label: 'Color',
        placeholder: 'Select a color',
        options: colorOptions,
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
      },
      tone: {
        label: 'Color Tone',
        placeholder: 'Select a tone',
        options: tones.map((tone) => ({
          hex: tone.hex,
          label: tone.label,
          value: tone.value,
        })),
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
      },
      personCount: {
        label: 'Person Count',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      personPosition: {
        label: 'Person Position',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      audience: {
        label: 'Audience',
        placeholder: 'Select an audience',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },
    };
  });

  const [posts, setPosts] = useState(null);

  const [socialMediaSort, setSocialMediaSort] = useState(() => {
    // TODO: causing issues with new sort options

    // const storedSort = localStorage.getItem('socialMediaSort');
    // if (storedSort) {
    //   const parsedSort = JSON.parse(storedSort);
    //   if (parsedSort && typeof parsedSort === 'object' && parsedSort.sort) {
    //     return parsedSort;
    //   }
    // }
    return {
      sort: {
        label: 'Sort',
        placeholder: 'Select a sort',
        options: [
          { label: 'Default', value: null },
          { label: 'Freshness', value: 'takenAt.desc' },
          // { label: 'Likes', value: 'takenAt.desc' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
    };
  });

  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const [socialMediaImageVersion, setSocialMediaImageVersion] =
    useState('full');

  // Fashion Week Queries
  const [
    fetchFashionWeekCities,
    {
      data: fashionWeekCities, // refetch: refetchFashionWeekCities
      // loading: fashionWeeksLoading,
      // error: fashionWeeksError
    },
  ] = useLazyQuery(EXPLORE_FASHION_WEEK_CITIES);

  const [
    fetchFashionWeekFilters,
    {
      data: fashionItemFiltersData,
      refetch: refetchFashionItemFilters, // loading: fashionItemFiltersLoading,
      // error: fashionItemsFilterError
    },
  ] = useLazyQuery(EXPLORE_FASHION_WEEK_FILTERS, {
    filters: returnFashionItemFilters(fashionWeekFilters),
  });

  // Social Media Queries
  const [
    fetchMarkets,
    {
      data: marketsData,
      // loading: marketsLoading
      // error: marketsError
    },
  ] = useLazyQuery(GET_MARKETS);

  const [
    fetchSocialMediaFilters,
    {
      data: socialMediaFiltersData,
      // loading: trendFiltersLoading,
      // error: trendFiltersError
    },
  ] = useLazyQuery(GET_TREND_FILTERS);

  const [fetchSocialWatchFilters, { data: socialWatchFiltersData }] =
    useLazyQuery(EXPLORE_SOCIALWATCH_FILTERS);

  // Fashion Weeks Filter Logic
  const initializeFashionItemFilters = () => {
    if (activeTab === 'fashion-weeks') {
      const fashionWeekItemFilters = {
        designer: {},
        category: {},
        type: {},
        season: {},
      };

      const filterData =
        fashionItemFiltersData?.exploreFashionWeekItemFilters || [];

      for (const filter of filterData) {
        const filterKey = filter.label;

        if (!fashionWeekItemFilters[filterKey]) continue;
        if (fashionWeekFilters[filterKey]?.value) continue;

        let options = filter.values.map((item) => ({
          ...item,
          label: startCase(item.label.replaceAll(/[-_]/g, ' ')),
        }));

        if (filterKey === 'season') {
          options = options
            .sort((a, b) => b.label.localeCompare(a.label))
            .sort((a, b) =>
              b.label
                .split(' ')
                .at(-1)
                .localeCompare(a.label.split(' ').at(-1)),
            );
        } else {
          options = options.sort((a, b) => a.label.localeCompare(b.label));
        }

        fashionWeekItemFilters[filterKey] = {
          ...fashionWeekFilters[filterKey],
          label: startCase(filter.label),
          placeholder: 'Select a ' + filter.label,
          options,
        };

        if (filterKey === 'season') {
          fashionWeekItemFilters[filterKey].value = null;
        }

        // selectSearchParam(fashionWeekItemFilters[filterKey], filterKey)
      }

      return fashionWeekItemFilters;
    }

    return {};
  };

  function initializeSocialMediaFilters() {
    const trendFilters = {
      category: {},
      color: {},
      pattern: {},
    };

    const filterData = socialMediaFiltersData?.trendFilters;

    if (!filterData) return trendFilters;

    for (const filter in filterData) {
      const filterOptions = filterData[filter];
      const filterMappings = {
        apparels: {
          label: 'Category',
          fieldName: 'trendsCategory',
        },
        colors: {
          label: 'Color',
          fieldName: 'trendsColor',
        },
        patterns: {
          label: 'Pattern',
          fieldName: 'trendsPattern',
        },
      };

      const filterMapping = filterMappings[filter];
      if (!filterOptions || !filterMapping) continue;

      trendFilters[filterMapping.fieldName] = {
        ...socialMediaFilters[filterMapping.fieldName],
        label: capitalize(filterMapping.label),
        placeholder: 'Select a ' + filterMapping.label.toLowerCase(),
        options: filterOptions?.map((item) => ({
          ...item,
          originalLabel: item.label,
          value: item?.value ? item?.value : item?.label,
          label: capitalize(item.label.replaceAll('-', ' ')),
        })),
      };
    }

    return trendFilters;
  }

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      refetchFashionItemFilters({
        filters: returnFashionItemFilters(fashionWeekFilters),
      });
    }
  }, [activeTab, fashionWeekFilters, refetchFashionItemFilters]);

  useEffect(() => {
    const updatedFilters = {};

    if (fashionWeekCities) {
      const cities = fashionWeekCities?.exploreFashionWeekCities;
      updatedFilters.fashionWeek = {
        ...fashionWeekFilters.fashionWeek,
        options: cities?.map((city) => ({
          label: startCase(city.replaceAll('-', ' ')),
          value: city,
        })),
      };
    }

    if (fashionItemFiltersData) {
      const { designer, category, type, season } =
        initializeFashionItemFilters();

      if (designer?.label) updatedFilters.designer = designer;
      if (category?.label) {
        updatedFilters.category = category;
      }
      if (season?.label) updatedFilters.season = season;
      if (type?.label) updatedFilters.type = type;
    }

    setFashionWeekFilters({
      ...fashionWeekFilters,
      ...updatedFilters,
    });
  }, [fashionWeekCities, fashionItemFiltersData]);

  // Social Media Filters Logic
  useEffect(() => {
    if (marketsData?.markets) {
      const marketOptions = marketsData.markets
        .map((market) => ({
          label: getMarketLabel(market),
          options: market.audiences
            .map((audience) => ({
              label: getAudienceLabel(audience),
              value: `${audience.ageMax}-${audience.ageMin}-${audience.gender}`,
              marketId: market.id,
              audVal: audience.id,
              location: getMarketLabel(market),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      const apparels = marketsData.markets
        .flatMap((m) => m.apparels)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value),
        )
        .map((apparel) => ({
          label: startCase(apparel.label.replaceAll('-', ' ')),
          value: String(apparel.value),
        }));

      setSocialMediaFilters((prev) => {
        prev.market.options = marketOptions;
        prev.category.options = apparels;

        return prev;
      });
    }
  }, [socialMediaFilters, marketsData]);

  useEffect(() => {
    const { category, pattern } = initializeSocialMediaFilters();

    const updatedFilters = {};

    if (category?.label) updatedFilters.category = category;
    if (pattern?.label) updatedFilters.pattern = pattern;

    setSocialMediaFilters({
      ...socialMediaFilters,
      ...updatedFilters,
    });
  }, [socialMediaFiltersData]);

  const handleFashionWeekFiltersOnChange = (item, filter) => {
    setFashionWeekFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (filter === 'search') {
        // Clear all other filters by creating new objects for each to avoid direct mutation
        Object.keys(newFilters).forEach((filterKey) => {
          newFilters[filterKey] = {
            ...newFilters[filterKey],
            value: null,
          };
        });
      } else {
        // Clear search filter by creating a new object for it
        newFilters.search = {
          ...newFilters.search,
          value: null,
        };
      }

      // Update the specified filter with the new item
      newFilters[filter] = {
        ...newFilters[filter],
        value: item,
      };

      // localStorage.setItem('fashionWeekFilters', JSON.stringify(newFilters));
      return newFilters;
    });
  };

  const handleSocialMediaSortOnChange = (item, sortKey) => {
    setSocialMediaSort((prevSort) => {
      return {
        ...prevSort,
        [sortKey]: {
          ...prevSort[sortKey],
          value: item,
        },
      };
    });
    localStorage.setItem('socialMediaSort', JSON.stringify(socialMediaSort));
  };

  const handleSocialMediaFiltersOnChange = (item, filter, hardSet = false) => {
    if (filter === 'color') {
      console.log('filter item', item);
    }

    setSocialMediaFilters((prevFilters) => {
      // Update the filter value
      const updatedFilters = {
        ...prevFilters,
        [filter]: {
          ...prevFilters[filter],
          value: hardSet
            ? item
            : prevFilters[filter].isMulti
              ? [
                  ...(prevFilters[filter].value || []),
                  Array.isArray(item) && item[0] ? item[0] : item,
                ]
              : item,
        },
      };

      // Further update for 'market' filter if conditions are met
      if (filter === 'market') {
        if (item === null) {
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: null,
          };
          updatedFilters.platform = {
            ...prevFilters.platform,
            value: null,
          };
        }

        const market = marketsData?.markets.find(
          (market) => market.id === item?.marketId,
        );

        const audience = market?.audiences.find((audience) => {
          const key = audience.id;

          return key === item?.audVal;
        });

        if (audience) {
          updatedFilters.market = {
            ...prevFilters.market,
            value: {
              label: item?.label,
              value: item?.audVal,
              location: item?.location,
            },
          };
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: audience.groups[0].audienceId,
          };
          updatedFilters.platform = {
            ...prevFilters.platform,
            options: audience.groups.map((platform) => ({
              label: platform.platform,
              value: platform.platform,
              audienceId: platform.audienceId,
            })),
            value: {
              label: audience.groups[0].platform,
              value: audience.groups[0].platform,
            },
          };
          if (audience.groups[0].features.socialwatch) {
            updatedFilters.timeframe = {
              ...prevFilters.timeframe,
              options: [
                {
                  label: 'Last Week',
                  value: 'last-week',
                  socialWatch: true,
                },
                {
                  label: 'Last Two Weeks',
                  value: 'last-two-weeks',
                  socialWatch: true,
                },
                ...seasons,
              ],
            };
          }
        }

        // TODO: This should become from the filters but...
        if (market?.apparels) {
          updatedFilters.category = {
            ...prevFilters.category,
            options: market?.apparels?.map((apparel) => ({
              label: startCase(apparel.label.replaceAll('-', ' ')),
              value: String(apparel.value),
            })),
          };
        }
      }

      // if (filter === 'platform') {
      //   updatedFilters.audience.value = item?.audienceId || null;
      //   updatedFilters.market.value.value = item?.audienceId || null;
      //   if (item === null) updatedFilters.market.value = null;
      // }

      if (filter === 'timeframe') {
        if (item?.socialWatch) {
          const variables = {
            audienceId: prevFilters.audience.value,
          };

          if (socialMediaFilters.category.value) {
            variables.apparel = prevFilters.category.value?.value;
          }

          fetchSocialWatchFilters({
            variables,
          });
        }
      }

      if (filter === 'category') {
        if (prevFilters.timeframe.value?.socialWatch) {
          const variables = {
            audienceId: prevFilters.audience.value,
          };

          if (item) {
            variables.apparel = item?.value?.toLowerCase();
          }

          fetchSocialWatchFilters({
            variables,
          });
        }
      }

      if (filter === 'color') {
        console.log('updatedFilters', updatedFilters);
      }

      // Return the updated filters for the state update
      // localStorage.setItem(
      //   'socialMediaFilters',
      //   JSON.stringify(updatedFilters),
      // );
      return updatedFilters;
    });
  };

  useEffect(() => {
    const socialWatchFilters =
      socialWatchFiltersData?.socialWatchFilters?.filters;
    if (socialWatchFilters) {
      const filters = Object.entries(socialWatchFilters)
        .filter(([key]) => key.startsWith('attributes') && key !== 'apparel')
        .reduce((obj, [key, value]) => {
          const pattern = startCase(key.split('.').at(-1).replaceAll('-', ' '));
          const options = value
            ?.map((item) => ({
              label:
                pattern + ': ' + startCase(item.value.replaceAll('-', ' ')),
              name: key.split('.').at(-1),
              value: item.value,
              group: pattern,
            }))
            .filter((item) => item.value !== 'N/A');

          if (!obj['attribute.custom']) {
            obj['attribute.custom'] = {
              label: 'Design',
              placeholder: 'Select a design',
              isClearable: true,
              isMulti: true,
              isSearchable: true,
              options: [],
            };
          }

          obj['attribute.custom'].options.push({
            label: pattern,
            options,
          });

          return obj;
        }, {});

      const newSocialMediaFilters = Object.entries(socialMediaFilters).filter(
        ([key]) => {
          if (key.startsWith('attributes') === false) return true;

          return Object.keys(filters).includes(key);
        },
      );

      setSocialMediaFilters({
        ...Object.fromEntries(newSocialMediaFilters),
        ...filters,
      });
    }
  }, [socialWatchFiltersData]);

  const [getTypeaheadSuggestions] = useLazyQuery(GET_TYPEAHEAD_SUGGESTIONS, {
    fetchPolicy: 'cache-first',
  });

  const loadFashionWeekSearchOptions = (inputValue, callback) => {
    const query = inputValue.toLowerCase();
    if (!query || query.length < 3) {
      // eslint-disable-next-line n/no-callback-literal
      return callback([]);
    }

    getTypeaheadSuggestions({
      variables: {
        query,
      },
      onCompleted: (data) => {
        const options = data?.typeaheadSuggestions?.options?.map((item) => ({
          label: item.label,
          value: item.value,
        }));

        callback(options);
      },
    });
  };

  function fashionWeekClearAll() {
    const updatedFilters = {};

    for (const filter in fashionWeekFilters) {
      updatedFilters[filter] = {
        ...fashionWeekFilters[filter],
        value: null,
      };
    }

    // clear search filter
    updatedFilters.search.value = '';

    setFashionWeekFilters(updatedFilters);
    // localStorage.removeItem('fashionWeekFilters');
  }

  function socialMediaClearAll() {
    const updatedFilters = {};

    for (const filter in socialMediaFilters) {
      updatedFilters[filter] = {
        ...socialMediaFilters[filter],
        value: null,
      };
    }

    updatedFilters.search.value = '';

    setSocialMediaFilters(updatedFilters);
    // localStorage.removeItem('socialMediaFilters');
    localStorage.removeItem('socialMediaSort');
  }

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionWeekCities();
      fetchFashionWeekFilters();
    } else if (activeTab === 'social-media') {
      fetchMarkets();
      fetchSocialMediaFilters({
        audience: null,
      });
    }
  }, [activeTab]);

  function clearAll() {
    if (activeTab === 'fashion-weeks') {
      fashionWeekClearAll();
    } else if (activeTab === 'social-media') {
      socialMediaClearAll();
    }
  }

  return (
    <ExploreContext.Provider
      value={{
        activeTab,
        setActiveTab,
        fashionWeekFilters,
        handleFashionWeekFiltersOnChange,
        socialMediaFilters,
        handleSocialMediaSortOnChange,
        socialMediaSort,
        handleSocialMediaFiltersOnChange,
        clearAll,
        fashionWeekClearAll,
        socialMediaClearAll,

        selectedSocialMediaAudience: socialMediaFilters.audience?.value,
        selectedSocialMediaColor: socialMediaFilters.color?.value,
        selectedSocialMediaCategory: socialMediaFilters.category.value,
        selectedSocialMediaTimeframe: socialMediaFilters?.timeframe?.value,
        selectedSocialMediaPersonCount: exploreAdvanced
          ? socialMediaFilters?.personCount?.value
          : null,
        selectedSocialMediaPersonPosition: exploreAdvanced
          ? socialMediaFilters?.personPosition?.value
          : null,
        selectedSocialMediaTone: socialMediaFilters.tone?.value,
        socialMediaSorting: socialMediaSort.sort.value,

        socialMediaImageVersion,
        setSocialMediaImageVersion,

        posts,
        setPosts,
        hasBannedApparel,
        setHasBannedApparel,

        loadFashionWeekSearchOptions,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => useContext(ExploreContext);
