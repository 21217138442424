import React, { useRef, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { useMutation, useQuery } from '@apollo/client';
import { Bookmark } from 'lucide-react';

import {
  ADD_ITEM_TO_COLLECTION,
  COLLECTIONS,
  CREATE_COLLECTION,
  GET_COLLECTION_ITEMS_GROUPED,
  GET_COLLECTION_ITEMS_MERGED,
} from '@/api/collections';
import { Search } from '@/assets/svg';
import { Button } from '@/components/Button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '@/components/Drawer';
import { cn } from '@/helpers/utils';
import { useFilters } from '@/hooks/filters';
import { useToast } from '@/hooks/useToast';
import { SidebarButton } from '@/pages/general/new-studio/components/SidebarMenu';

import { CollectionItem } from './CollectionItem';

export function CreateNewCollection({ onCreateComplete = () => {} }) {
  const [newCollectionName, setNewCollectionName] = useState('');
  const [showInput, setShowInput] = useState(false);

  const toast = useToast();

  const [createCollection, { loading }] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [
      {
        query: COLLECTIONS,
        variables: {
          cursor: null,
          includeOrganizationViewable: true,
        },
      },
    ],
    onCompleted: (data) => {
      onCreateComplete(data.createCollection.id || null);

      setNewCollectionName('');
      setShowInput(false);

      toast.createToast({
        message: `Collection with the name ${newCollectionName} created!.`,
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });
    },
  });

  return (
    <div
      className={cn(
        'flex w-full border border-secondary/50 py-2 px-2 rounded items-center transition-colors',
        !showInput &&
          'hover:bg-[#FAF8F8] hover:text-primary hover:border-primary bg-secondary/5 text-night justify-center cursor-pointer font-semibold rounded',
        loading && 'opacity-70 cursor-not-allowed',
      )}
      onClick={() => {
        if (!showInput) setShowInput(true);
      }}
    >
      {!showInput && <span>Create New Collection</span>}
      {showInput && (
        <div className="col-span-1 flex w-full flex-col gap-x-1.5">
          <div className="flex items-center gap-2">
            <input
              className="w-full border-b border-black bg-transparent
              px-4 pb-[0.375rem] pl-0 text-sm outline-none"
              placeholder="New collection name"
              value={newCollectionName}
              onChange={(e) => setNewCollectionName(e.target.value)}
            />
            {/* Close Button */}
            <button
              disabled={loading}
              className="flex cursor-pointer items-center justify-center gap-2 rounded
            border border-transparent bg-primary py-1 px-2 text-sm font-semibold text-white transition-all hover:border hover:border-primary hover:bg-white hover:text-primary"
              onClick={() => {
                createCollection({
                  variables: {
                    name: newCollectionName,
                  },
                });
              }}
            >
              {loading && <ImSpinner8 className="animate-spin" />}
              Create
            </button>
            <button
              type="button"
              onClick={() => setShowInput(false)}
              className="flex cursor-pointer items-center justify-center gap-2 rounded border border-transparent bg-white py-1 px-2 text-sm font-semibold text-night transition-all hover:border hover:border-primary hover:bg-white hover:text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export const SaveToCollection = ({
  id,
  type,
  collections,
  children,
  variant = 'default',
  asChild = false,
}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [search, setSearch] = useState('');

  const { selectedSeason } = useFilters();

  const closeButtonRef = useRef();

  const toast = useToast();

  const { data, loading, error } = useQuery(COLLECTIONS, {
    variables: {
      cursor: null,
      includeOrganizationViewable: true,
    },
  });

  const [addItem] = useMutation(ADD_ITEM_TO_COLLECTION, {
    refetchQueries: [
      {
        query: COLLECTIONS,
        variables: {
          cursor: null,
        },
      },
      {
        query: GET_COLLECTION_ITEMS_MERGED,
        variables: {
          collection: selectedCollection?.id,
        },
      },
      {
        query: GET_COLLECTION_ITEMS_GROUPED,
        variables: {
          collection: selectedCollection?.id,
        },
      },
    ],
    onCompleted: () => {
      toast.createToast({
        message: `Item has been successfully added to the ${selectedCollection?.name || ''} collection.`,
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });

      closeButtonRef.current?.click();
    },
    onError() {
      toast.createToast({
        message:
          'An error occurred when adding item to selected collection. Please try again.',
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
    },
  });

  function handleSave(collectionId) {
    addItem({
      variables: {
        collection: collectionId,
        season: selectedSeason,
        type,
        value: id,
      },
    });
  }

  const renderData = search
    ? data?.collections?.data.filter((c) =>
        c.name.toLowerCase().includes(search.toLowerCase()),
      )
    : data?.collections?.data;

  if (loading) {
    return (
      <Drawer>
        <DrawerTrigger>
          {typeof children === 'function' ? (
            children(loading)
          ) : variant === 'icon' ? (
            <button
              className="cursor-not-allowed rounded border border-secondary/10 bg-white p-1 text-secondary"
              disabled
            >
              <Bookmark size={20} />
            </button>
          ) : (
            <button className="cursor-not-allowed bg-white px-4 py-1 font-medium text-charcoal">
              Save
            </button>
          )}
        </DrawerTrigger>
      </Drawer>
    );
  }

  if (error)
    return (
      <button
        className="cursor-not-allowed rounded border border-secondary/10 bg-white p-1 text-secondary"
        disabled
      >
        <Bookmark size={20} />
      </button>
    );

  return (
    <Drawer>
      <DrawerTrigger>
        {typeof children === 'function'
          ? children(loading)
          : asChild
            ? children
            : variant === 'default' && (
                <Button color="white" size="xs">
                  Save
                </Button>
              )}
        {variant === 'icon' && (
          <button className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
            <Bookmark size={20} />
          </button>
        )}
        {variant === 'studio' && (
          <SidebarButton
            text="Save"
            className="border bg-primary py-2 px-4 font-bold text-white after:border-primary"
          />
        )}
      </DrawerTrigger>
      <DrawerContent>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <DrawerClose ref={closeButtonRef}>
                <IoMdClose size={26} />
              </DrawerClose>

              <h1 className="text-xl font-semibold">
                Add to a different collection
              </h1>
            </div>
          </div>

          <div className="relative mt-[1.4375rem] mb-[0.375rem] flex w-full">
            <input
              className="font-regular w-full border-b border-[#000000] bg-transparent
              pt-0 pr-[1.625rem] pb-[0.375rem] pl-0 text-[1.125rem] leading-[1.674375rem] outline-none"
              placeholder="Search in Collections..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="absolute right-0">
              <Search />
            </div>
          </div>

          <div className="flex max-h-[calc(100vh-15rem)] flex-col gap-4 overflow-auto">
            {renderData.map((collection) => (
              <CollectionItem
                key={collection.id}
                item={collection}
                {...{ selectedCollection, setSelectedCollection, handleSave }}
                alreadySaved={collections?.includes(id)}
              />
            ))}
          </div>
          <CreateNewCollection onCreateComplete={handleSave} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};
