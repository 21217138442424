import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { X } from 'lucide-react';

import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton } from './components/SidebarMenu';
import { StudioToolsGrid } from './components/StudioUtils';

export const StudioTools = () => {
  const { setOpenTools, designTask } = useStudioContext();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('home')) {
      setOpenTools(false);
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute top-0 right-[7rem] z-[1001] flex h-full w-[28rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start gap-6 bg-white p-6">
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="flex gap-4">
            <SidebarButton
              text="Save"
              className="border bg-primary py-2 px-4 font-bold text-white after:border-primary"
              onClick={() => console.log('Saved')}
            />
            <SidebarButton
              text="Download"
              className="py-2 px-4 font-bold"
              onClick={() => console.log('Downloaded')}
            />
          </div>
          <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
            <X
              size={36}
              onClick={() => setOpenTools(false)}
              strokeWidth={1.5}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 pt-2">
          <h2 className="text-2xl font-bold leading-6 text-night">
            Design Tools
          </h2>
        </div>
        <StudioToolsGrid />
        <div className="flex w-full flex-col gap-2">
          <h3 className="text-lg font-bold leading-8 text-night">Prompt</h3>
          <p className="text-sm text-night">
            {designTask?.payload.positivePrompt}
          </p>
          <p className="text-xs font-bold text-secondary">
            {dayjs(designTask?.createdAt).format('D MMMM YYYY')}
          </p>
        </div>
        <div className="flex w-full flex-col gap-2">
          <h3 className="text-lg font-bold leading-8 text-night">Size</h3>
          <p className="text-sm font-bold text-secondary">
            {designTask?.payload.orientation.label}
          </p>
        </div>
      </div>
    </div>
  );
};
