import { gql } from '@apollo/client';

export const GENERATE_STUDIO_ITEM = gql`
  mutation GenerateStudioItem($payload: GenerateImageInput!) {
    generateStudioItem(payload: $payload) {
      id
    }
  }
`;

export const STUDIO_TASK = gql`
  mutation studioTask($id: ID!) {
    studioTask(id: $id) {
      id
      seed
      images {
        url
        width
        height
        blurhash
        studioItemId
      }
      organization {
        id
        name
      }
      createdBy {
        id
        fullName
      }
      visibility
      status
      payload
      createdAt
      generateTaskType
    }
  }
`;

export const UPDATE_FILE_STATUS = gql`
  mutation Mutation($fileId: ID!, $status: STATUS!) {
    updateFileStatus(fileId: $fileId, status: $status)
  }
`;

export const NEW_PROMPT_SUGGESTION = gql`
  mutation StudioSuggestNewPrompts(
    $prompt: String!
    $generationType: GenerationType!
  ) {
    studioSuggestNewPrompts(prompt: $prompt, generationType: $generationType) {
      prompt
      id
      suggestions {
        key
        value
      }
    }
  }
`;

export const STUDIO_BG_REMOVER = gql`
  mutation StudioBackgroundRemover($payload: BackgroundRemoverInput!) {
    studioBackgroundRemover(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_INPAINTING = gql`
  mutation StudioInpainting($payload: InpaintingInput!) {
    studioInpainting(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_ZOOM_IN = gql`
  mutation StudioImageZoomIn($payload: ZoomInImageInput!) {
    studioImageZoomIn(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_ZOOM_OUT = gql`
  mutation StudioImageZoomOut($payload: ZoomOutImageInput!) {
    studioImageZoomOut(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_UPSCALE = gql`
  mutation StudioImageUpscale($payload: UpscaleImageInput!) {
    studioImageUpscale(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_COLOR_TRANSFER = gql`
  mutation StudioColorTransfer($payload: ColorTransferInput!) {
    studioColorTransfer(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_REALIZE_SKETCH = gql`
  mutation StudioRealizeSketch($payload: RealizeSketchInput!) {
    studioRealizeSketch(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_CREATE_VARIANTS = gql`
  mutation StudioCreateVariants($payload: CreateVariantsInput!) {
    studioCreateVariants(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_IMAGE_MANIPULATION = gql`
  mutation StudioImageManipulation($payload: ImageManipulationInput!) {
    studioImageManipulation(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_GENERATE_PATTERN = gql`
  mutation StudioGeneratePattern($payload: GeneratePatternInput!) {
    studioGeneratePattern(payload: $payload) {
      id
      taskId
    }
  }
`;

export const SUGGESTION_BENCHMARK = gql`
  mutation StudioSelectSuggestedPrompt($payload: SelectPromptSuggestion!) {
    studioSelectSuggestedPrompt(payload: $payload) {
      id
    }
  }
`;

export const STUDIO_IMAGE_UPSCALE = gql`
  mutation StudioImageUpscale($payload: UpscaleImageInput!) {
    studioImageUpscale(payload: $payload) {
      id
      taskId
    }
  }
`;
