import { useEffect, useMemo, useRef, useState } from 'react';

import { FormLabel } from '@/components/FormInput';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/helpers/utils';

export function DropdownSelectItem({
  filter,
  filterKey,
  onChange,
  closeOnChange = true,
}) {
  const triggerRef = useRef(null);

  useEffect(() => {
    function handleFocus(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        setSearch((prev) => prev + ' ');
      }
    }

    triggerRef.current.addEventListener('keydown', handleFocus);

    return () => {
      triggerRef.current.removeEventListener('keydown', handleFocus);
    };
  }, [triggerRef]);

  const [search, setSearch] = useState('');
  const searchData = useMemo(() => {
    if (!search || search === '') {
      return filter?.options;
    }

    return filter?.options?.filter((option) => {
      const labelIncludesSearch = option.label
        .toLowerCase()
        .includes(search.toLowerCase());

      if (option.options && option.options.length > 0) {
        return (
          labelIncludesSearch ||
          option.options.some((subOption) =>
            subOption.label.toLowerCase().includes(search.toLowerCase()),
          )
        );
      }

      return labelIncludesSearch;
    });
  }, [filter.options, search]);

  const isGroup = searchData && searchData[0]?.options;

  return (
    <AccordionItem value={filterKey}>
      <FormLabel>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center gap-6 mt-2 border rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal [&_div]:data-[state=open]:hidden [&_div]:data-[state=closed]:flex [&_input]:data-[state=open]:block [&_input]:data-[state=closed]:hidden',
          filter.value ? 'text-charcoal' : 'text-[#A1A1A1]',
        )}
      >
        <div className="flex items-center gap-2">
          {filterKey === 'color' && filter.value && (
            <h3
              className="block h-4 w-4 rounded-full"
              style={{ backgroundColor: filter.value.hex }}
            />
          )}
          <span className="max-w-fit text-[0.875rem] leading-5">
            {filterKey === 'attribute.custom'
              ? Array.isArray(filter.value) && filter.value.length > 0
                ? filter.value.map((v) => v.label).join(', ')
                : filter?.placeholder
              : filter.value?.label || filter?.placeholder}
          </span>
        </div>
        <input
          className="w-full outline-none"
          type="text"
          value={search}
          placeholder={filter?.placeholder}
          onClick={(e) => e.preventDefault()}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSearch(e.target.value);
          }}
        />
      </AccordionTrigger>
      <AccordionContent
        className={cn(
          'rounded-md right-0 !z-[999] mt-2 w-full min-w-[230px] bg-[#F7F7F7]  outline-none p-2 max-h-96 overflow-y-auto',
        )}
      >
        {isGroup ? (
          <Accordion
            collapsible
            type="single"
            className="flex flex-col gap-2"
            defaultValue={searchData[0]?.label
              .toLowerCase()
              .replace(/\s/g, '-')}
          >
            {searchData.map((option, index) => (
              <AccordionItem
                key={index}
                value={option.label.toLowerCase().replace(/\s/g, '-')}
              >
                <AccordionTrigger className="last-of-type::mb-0 rounded p-2 data-[state=closed]:bg-[#FFFDFD]">
                  {option.label}
                </AccordionTrigger>
                <AccordionContent>
                  {option.options.map((subOption, subIndex) => (
                    <div
                      key={subIndex}
                      onClick={() => {
                        onChange(subOption, filterKey);
                        closeOnChange && triggerRef?.current?.click();
                      }}
                      className="cursor-pointer rounded border border-transparent px-2 py-[.625rem] text-[0.875rem] leading-5 transition-all hover:border-[#1A1A1A/5] hover:bg-[#FFFDFD]"
                    >
                      {subOption.label}
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          searchData?.map((option, index) => (
            <div
              onClick={() => {
                onChange(option, filterKey);
                closeOnChange && triggerRef?.current?.click();
              }}
              key={index}
              className="flex cursor-pointer items-center gap-2 rounded border border-transparent px-2 py-[.625rem] text-[0.875rem] leading-5 transition-all hover:border-[#1A1A1A/5] hover:bg-[#FFFDFD]"
            >
              {option.hex && (
                <div
                  className="h-4 w-4 rounded-full"
                  style={{ backgroundColor: option.hex }}
                />
              )}
              {option.label}
            </div>
          ))
        )}
      </AccordionContent>
    </AccordionItem>
  );
}

export function DropdownSelect({ children, ...props }) {
  return <Accordion {...props}>{children}</Accordion>;
}
