import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { debounce, upperFirst } from 'lodash';
import {
  ChevronLeft,
  Copy,
  Earth,
  Eraser,
  HelpCircle,
  History,
  Home,
  ImagePlus,
  Lock,
  NotebookPen,
  Palette,
  Plus,
  Replace,
  Sparkles,
  SquareAsterisk,
  Trash,
  Wand,
} from 'lucide-react';

import {
  NEW_PROMPT_SUGGESTION,
  SUGGESTION_BENCHMARK,
} from '@/api/studio/mutations';
import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Textarea } from '@/components/ui/textarea';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';

import {
  CategoryCard,
  SidebarButton,
  SidebarCard,
  SidebarHeader,
  SidebarTitle,
} from './components/SidebarMenu';
import { UploadBox } from './components/StudioUtils';

export const StudioLeftSidebar = ({ isEditing, refImages }) => {
  const [describing, setDescribing] = useState(false);
  const [addNegativeKeywords, setAddNegativeKeywords] = useState(false);
  const [selectedAdviceId, setSelectedAdviceId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState({
    selectedTemplateId: null,
    selectedTemplateIndex: null,
  });
  const [promptId, setPromptId] = useState(null);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [descHistory, setDescHistory] = useState([]);
  const [generationType, setGenerationType] = useState(null);

  const toast = useToast();

  const navigate = useNavigate();

  const location = useLocation();

  const isHome = location.pathname.includes('home');

  const { data: historyData } = useQuery(GET_STUDIO_TASK_HISTORY);

  useEffect(() => {
    const positivePrompts = historyData?.studioTaskHistory?.edges
      ?.map((edge) => edge?.node?.payload?.positivePrompt)
      .filter(Boolean);

    if (positivePrompts) {
      setDescHistory(positivePrompts);
    }
  }, [historyData]);

  const {
    referenceError,
    designTask,
    handleCreateApparel,
    handleCreatePattern,
    handleCreateFromSketch,
    setNewPayload,
    handleTitleIconChanges,
    createItem,
    setCreateItem,
    setGeneratedImages,
    setOrientation,
    setOpenTools,
    setIsTile,
    setIsGenerating,
    setIsPreview,
    setIsHome,
    clearDesignTask,
    generateType,
    setGenerateType,
    positivePrompt,
    setPositivePrompt,
    negativePrompt,
    setNegativePrompt,
    generateSize,
    setGenerateSize,
    generateVisibility,
    setGenerateVisibility,
  } = useStudioContext();

  useEffect(() => {
    if (createItem) setGenerationType(createItem.type);
  }, [createItem]);

  useEffect(() => {
    setNewPayload((prev) => ({
      ...prev,
      positivePrompt,
      negativePrompt,
      orientation: {
        label: generateSize,
        value: generateSize.toUpperCase(),
      },
      visibility: generateVisibility,
    }));
  }, [positivePrompt, negativePrompt, generateSize, generateVisibility]);

  useEffect(() => {
    if (isEditing) {
      if (refImages?.sketches?.length > 0) {
        setCreateItem({
          icon: NotebookPen,
          text: 'Create from Sketch',
          type: 'sketch',
        });
      } else if (refImages?.images?.length > 0) {
        setCreateItem({
          icon: Plus,
          text: 'Create an Apparel',
          type: 'apparel',
        });
      } else if (refImages?.patterns?.length > 0) {
        setCreateItem({
          icon: Plus,
          text: 'Create a Pattern',
          type: 'pattern',
        });
      }
    }
  }, [isEditing, refImages]);

  useEffect(() => {
    if (designTask?.error) {
      setIsGenerating(false);
      toast.createToast({
        message:
          'An error occurred while generating the design. Please try again later.',
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
    }
    if (designTask?.loading === true) {
      setIsGenerating(true);
    } else if (!designTask?.error && designTask?.loading === false) {
      setIsGenerating(false);
      setIsTile(true);
      setOrientation(designTask?.payload?.orientation?.value);
      setGeneratedImages(
        designTask?.images.map((image) => ({
          url: image.url,
          studioItemId: image.studioItemId,
          width: image.width,
          height: image.height,
        })),
      );
      setDescHistory([...descHistory, positivePrompt]);
    }
  }, [designTask]);

  const [promptSuggestions, { loading: promptLoading, error: promptError }] =
    useMutation(NEW_PROMPT_SUGGESTION, {
      notifyOnNetworkStatusChange: true,
    });

  const debouncedSuggestions = useCallback(
    debounce((value) => {
      if (value.trim()) {
        setGenerateType('ai-gen');
        promptSuggestions({
          variables: {
            prompt: value,
            generationType: generationType.toUpperCase(),
          },
          onCompleted: (data) => {
            setPromptId(data.studioSuggestNewPrompts.id);
            setSuggestedPrompts(data.studioSuggestNewPrompts.suggestions);
          },
        });
      }
    }, 1200),
    [promptSuggestions, generationType],
  );

  const [suggestionBenchmark] = useMutation(SUGGESTION_BENCHMARK);

  const handlePromptChange = (e) => {
    const value = e.target.value;
    setPositivePrompt(value);
    debouncedSuggestions(value);
    setDescribing(true);
  };

  const handleSelectedPrompt = (index, selectedAdvice, suggestionId) => {
    setSelectedAdviceId(index);
    setPositivePrompt(upperFirst(selectedAdvice));
    suggestionBenchmark({
      variables: {
        payload: {
          promptId,
          suggestionId,
        },
      },
    });
  };

  const handleGenerate = () => {
    if (generationType === 'apparel') {
      handleCreateApparel();
    } else if (generationType === 'pattern') {
      handleCreatePattern();
    } else if (generationType === 'sketch') {
      handleCreateFromSketch();
    } else {
      handleCreateApparel();
    }
    setIsHome(false);
    setIsGenerating(true);
    setIsPreview(false);
    setIsTile(false);
    navigate('/studio');
  };

  const { isEnabled: isPrivateImageEnabled } = useFeatureFlag(
    FEATURE_FLAGS.PRIVATE_IMAGE,
  );

  const patternTemplates = [
    {
      image: '/assets/studio/studio-templates/floral.jpg',
      title: 'Floral',
      description: 'Elegant, botanical, vintage-inspired',
      list: [
        'A delicate and intricate fashion pattern inspired by traditional Chinoiserie, featuring elegant, stylized floral motifs. The design showcases large, sweeping peonies, cherry blossoms, and lotus flowers, each rendered with detailed linework and graceful curves. The flowers are surrounded by fine tendrils and subtle leaves, all arranged in a flowing, seamless pattern that exudes sophistication. The color palette is soft and refined, with pale blues, blue and dark blue tones, creating a luxurious yet gentle aesthetic. The design maintains the classic Chinoiserie charm, focusing purely on the ornate floral elements to evoke a sense of timeless beauty and elegance.',
        "A refined, vintage-inspired fashion pattern featuring an elegant blend of delicate wildflowers such as daisies, violets, and baby's breath. The design showcases intricate, soft foliage in muted green tones, with flowers rendered in gentle shades of soft pink and earthy browns. The pattern emphasizes subtle floral arrangements, where the flowers gracefully intertwine with slender vines and leaves, creating a balanced, non-dominant aesthetic. The composition evokes a nostalgic charm, with a soft, timeworn feel, perfect for fashion pieces that aim to capture a vintage look with a natural and understated beauty.",
        'A bold, Western-inspired fashion pattern featuring stylized floral motifs such as prairie roses, desert poppies, and sunflowers, arranged alongside intricate scrollwork and paisley elements. The flowers are rendered in warm, earthy tones of terracotta, soft pink, and sage green, evoking the rustic beauty of the American West. The design emphasizes both floral and geometric shapes, with subtle cowboy-inspired details like rope borders and woven textures. This pattern blends floral elements with Western heritage, creating a design that is both rugged and romantic, perfect for fashion pieces that capture the spirit of the frontier.',
        'A modern, high-fashion botanical pattern featuring lush, overlapping green leaves, ferns, and abstract vines arranged in a regular, continuous layout. The design highlights the various shades of green, from deep forest tones to light, fresh greens, creating a layered, textured effect. The pattern is clean and structured, with each botanical element repeating seamlessly to form a consistent and balanced flow. Subtle shading and fine detailing give the design a polished, contemporary look, ideal for fashion pieces that aim to evoke a fresh, nature-inspired aesthetic with a modern twist.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/animal.jpg',
      title: 'Animal',
      description: 'Wild, exotic, fierce, playful',
      list: [
        'A high-quality fashion pattern featuring the iconic leopard print, rendered in a sleek and modern style. The design showcases small, irregular rosettes with black spots against a golden-brown background, creating a rich, textured effect. The rosettes repeat seamlessly across the pattern, maintaining a fluid and continuous flow. The subtle shading within the spots adds depth, making the pattern dynamic and visually striking. This print is perfect for bold, luxurious fashion pieces that exude elegance and power.',
        'A high-fashion zebra pattern, designed with bold, clean black and white stripes that repeat seamlessly in a continuous flow. The stripes curve and intertwine naturally, mimicking the organic texture of zebra fur while maintaining a crisp and modern look. The high contrast between the black and white creates a striking visual impact, ideal for sophisticated, statement-making fashion pieces. The design exudes a timeless, edgy appeal, perfect for high-end fashion collections.',
        'A contemporary cowhide-inspired fashion pattern featuring irregular, black and white patches in a continuous, flowing layout. The bold, contrasting spots are softened with subtle gradients and textures, giving the pattern a modern, high-quality finish. The organic shapes of the patches blend seamlessly, maintaining a consistent and fluid design. This pattern brings a rustic yet refined appeal, making it perfect for fashion pieces that blend natural elements with a high-fashion aesthetic.',
        'A sleek, high-fashion jaguar print featuring irregular black and dark brown rosettes against a warm tan background. The pattern is designed to flow seamlessly, with each rosette blending into the next in a continuous, organic manner. Subtle highlights and shadows within the spots give the print depth and a realistic texture, while maintaining a polished, modern look. This jaguar pattern is perfect for high-end fashion pieces that combine wild, exotic elegance with contemporary style.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/geometric.jpg',
      title: 'Geometric',
      description: 'Modern, abstract, minimalist, graphic',
      list: [
        'A timeless houndstooth pattern featuring sharp black and white contrasts, each piece precisely aligned in a checkered layout. The pattern creates a bold, dynamic aesthetic that exudes sophistication and modernity. The clean, angular lines are complemented by a soft wool texture, adding warmth and depth to the classic geometric style, making it ideal for outerwear and structured garments.',
        'A refined herringbone pattern with crisp, diagonal stripes intersecting to form a repeating "V" motif. The design plays with light and shadow, offering a subtle dimensionality to the fabric. The warm, earthy tones, from taupe to dark brown, paired with a matte cotton texture, evoke a rustic elegance, perfect for high-end suiting or casual, tailored jackets.',
        'A vibrant chevron pattern with wide, alternating zigzag lines in bold primary colors. The geometric shapes stand out, creating a striking visual rhythm, accentuated by a satin finish for a sleek, glossy look. The contrast between the colors and sharp angles gives the pattern a contemporary edge, ideal for statement pieces in fashion collections.',
        'A classic argyle pattern reimagined with oversized diamond shapes in rich, autumnal tones of burgundy, forest green, and deep mustard. The intersecting lines in complementary hues add a layer of intricacy to the design. The woolen texture, with a hint of raised embroidery, gives the pattern a vintage yet luxurious feel, perfect for knitwear and cozy sweaters.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/paisley.jpg',
      title: 'Paisley',
      description: 'Bohemian, intricate, exotic, whimsical',
      list: [
        'A traditional paisley pattern featuring ornate teardrop motifs, each intricately detailed with fine linework. The design flows gracefully across the fabric, rendered in a luxurious color palette of deep emerald, sapphire, and gold. The smooth silk texture enhances the richness of the design, making it perfect for evening wear or sophisticated scarves.',
        'A boho-inspired paisley pattern with large, free-flowing shapes in earthy tones of burnt sienna, mustard yellow, and olive green. The pattern feels organic, with swirling motifs and soft, hand-drawn lines, paired with a soft, woven cotton texture, giving it a relaxed, artisanal feel suitable for casual boho-chic pieces.',
        'A sleek, modern take on paisley, featuring clean, minimal black and white teardrop shapes. The simplicity of the monochrome palette gives the design a contemporary edge, while the smooth satin finish adds a touch of elegance. The pattern is perfect for a sophisticated, minimalist aesthetic, ideal for formal garments or accessories.',
        'A lively paisley pattern bursting with bright, contrasting hues of pink, turquoise, and orange. The intricate motifs intertwine in a playful, overlapping layout, creating a vibrant, dynamic effect. The soft velvet texture adds depth and luxury to the design, making it perfect for eye-catching statement pieces in fashion collections.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/polka-dot.jpg',
      title: 'Polka Dot',
      description: 'Playful, retro, whimsical, timeless',
      list: [
        'A delicate polka dot pattern with tiny, evenly spaced dots on a soft pastel background. The dots are rendered in muted tones, like soft lavender and dusty pink, creating a gentle, understated look. The fabric has a silky, smooth texture, making the pattern ideal for blouses or light summer dresses.',
        'A bold polka dot pattern with oversized, contrasting dots in black and white. The sharp, clean edges of the dots create a striking visual impact, enhanced by a glossy satin finish. The pattern is perfect for bold, contemporary fashion statements, especially in structured garments like skirts or blazers.',
        'A playful, vibrant polka dot pattern featuring bright, multicolored dots scattered randomly on a neutral backdrop. The dots vary in size, adding a sense of fun and spontaneity to the design. The fabric has a smooth, matte texture, making it perfect for casual, youthful clothing pieces.',
        'A refined, minimalist polka dot design with crisp black dots on a white background. The pattern is clean and structured, with evenly spaced dots creating a classic yet modern look. The soft linen texture gives the fabric a sophisticated finish, ideal for elegant, everyday wear.',
      ],
    },
  ];

  const templates = [
    {
      image: '/assets/studio/studio-templates/denim-on-denim.jpg',
      title: 'Denim on Denim',
      description:
        'Layered denim, urban cool, effortless edge, modern versatility',
      list: [
        'An establishing shot capturing the full-body of a chic, edgy woman figure in a denim on denim look. She is dressed in a cropped light-wash denim jacket with frayed edges, paired with high-waisted dark-wash skinny jeans. The outfit features tan leather ankle boots, a white crop top, and gold hoop earrings. The backdrop is an urban street setting, subtly reinforcing the effortlessly cool and modern vibe of the outfit.',
        'An establishing shot capturing the full-body of a stylish, laid-back woman figure in a denim on denim look. She is dressed in an oversized, medium-wash denim shirt tucked into wide-leg jeans of the same wash. The outfit is completed with a black statement belt and pointed-toe heels. The backdrop is a trendy street scene, subtly enhancing the relaxed yet fashionable streetwear-inspired vibe.',
        'An establishing shot capturing the full-body of a rugged, modern man figure in a denim on denim outfit. He is dressed in a dark-wash oversized denim jacket layered over a light-wash denim shirt, paired with straight-leg medium-wash jeans. The look features black Chelsea boots and a silver chain. The backdrop is a sleek urban environment, subtly reinforcing the polished, masculine style.',
        'An establishing shot capturing the full-body of a casually stylish man figure in a rugged denim on denim look. He is dressed in a distressed light-wash denim jacket paired with classic dark-wash jeans, and a fitted white T-shirt underneath. The look is completed with brown suede desert boots and a leather belt. The backdrop is a rugged outdoor setting, subtly enhancing the effortless, masculine vibe of the outfit.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/white-noise.jpg',
      title: 'White Noise',
      description:
        'Sleek minimalism, neutral palette, refined elegance, effortless chic',
      list: [
        'An establishing shot capturing the full-body of a sleek, minimalist woman figure embodying the theme of White Noise aesthetic. She is dressed in a clean, long white overcoat with sharp lines, paired with a soft grey turtleneck and tailored white trousers. The look features white leather loafers and a minimalist silver wristwatch. The backdrop is a tranquil, neutral-toned setting, subtly enhancing the calm and refined theme of the outfit.',
        'An establishing shot capturing the full-body of a relaxed, minimalist woman figure embodying the White Noise aesthetic. She is dressed in an oversized, off-white cashmere sweater with straight-leg beige trousers and white sneakers. The look is completed with a light grey crossbody bag and silver hoop earrings. The backdrop is a serene, minimalist urban setting, subtly reinforcing the calm, quiet sophistication of the outfit.',
        'An establishing shot capturing the full-body of a sleek, monochromatic woman figure evoking the White Noise aesthetic. She is dressed in a crisp white button-down shirt paired with high-waisted ivory wide-leg trousers. The look features minimalist white sneakers and a light grey wool coat. The backdrop is a modern, minimalist studio setting, subtly enhancing the clean lines and serene elegance of the outfit.',
        'An establishing shot capturing the full-body of a minimalist woman figure representing the White Noise aesthetic. She is dressed in a long, flowing grey tunic dress with soft pleats, paired with white slip-on sandals and a light grey scarf. The look is complemented with a structured white handbag. The backdrop is a peaceful natural environment, subtly evoking the calm and neutral tone of the outfit.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/y2k.jpg',
      title: 'Y2K',
      description: 'Bold, futuristic, low-rise, playful, nostalgic',
      list: [
        'An establishing shot capturing the full-body of a playful, bold woman figure embracing the Y2K aesthetic. She is dressed in low-rise flare jeans with bedazzled details and a fitted neon green halter top. The look features chunky platform sneakers, a glittery trucker hat, and layered beaded necklaces. The backdrop is a vibrant, futuristic setting, subtly reinforcing the retro, playful Y2K vibe.',
        'An establishing shot capturing the full-body of a bold, statement-making woman figure embracing the Y2K aesthetic. She is dressed in a metallic silver crop top paired with a low-rise denim mini skirt and fishnet tights. The look features chunky colorful sneakers and a wide belt with a large buckle. The backdrop is a dynamic cityscape with neon lights, subtly enhancing the fun, futuristic 2000s look.',
        'An establishing shot capturing the full-body of a fashionable, trendy woman figure in a Y2K-inspired outfit. She is dressed in a graphic baby tee with a bold brand logo, paired with low-rise cargo pants and chunky platform sandals. The look is accessorized with a mini backpack and large hoop earrings. The backdrop is a nostalgic street scene, subtly enhancing the effortless, cool Y2K aesthetic.',
        'An establishing shot capturing the full-body of a standout woman figure evoking the Y2K aesthetic. She is dressed in a fitted iridescent tube top paired with wide-leg light-wash jeans. The look features chunky sneakers, a pink velour zip-up hoodie, and a trucker hat. The backdrop is a playful, colorful environment, subtly enhancing the bold, early 2000s aesthetic.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/western-gothic.jpg',
      title: 'Western Gothic',
      description:
        'Dark allure, fringed details, gothic cowboy, rebellious elegance',
      list: [
        'An establishing shot capturing the full-body of a dramatic, elegant woman figure embodying the Western Gothic aesthetic. She is dressed in a black suede tassel jacket with silver embellishments, paired with a floor-length deep burgundy tulle skirt. The look features spider-embroidered cowboy boots, a wide-brim hat, and silver skull rings. The backdrop is a moody, rural setting, subtly enhancing the macabre, frontier-inspired look.',
        'An establishing shot capturing the full-body of a bold, edgy woman figure embodying the Western Gothic aesthetic. She is dressed in a structured black leather corset under a long fringed tassel jacket, paired with high-waisted flared jeans and black cowboy boots adorned with silver studs. The look is completed with a black cowboy hat and layered silver necklaces. The backdrop is a rugged, western landscape, subtly enhancing the eerie, rugged elegance of the outfit.',
        'An establishing shot capturing the full-body of a dark, fashionable woman figure in a Western Gothic look. She is dressed in a fitted black tassel jacket with intricate embroidery over a sheer black tulle dress with lace details. The look features distressed black cowboy boots and a wide-brim hat. The backdrop is a misty, gothic setting, subtly enhancing the moody, frontier-inspired look.',
        'An establishing shot capturing the full-body of a striking woman figure embodying the Western Gothic aesthetic. She is dressed in a black velvet tassel jacket with leather accents, paired with a long tulle skirt in black and dark plum layers. The look features tall pointed-toe cowboy boots and a wide-brim hat with metal detailing. The backdrop is a desolate western scene, subtly enhancing the gothic allure of the outfit.',
      ],
    },
    {
      image: '/assets/studio/studio-templates/preppy-style.jpg',
      title: 'Preppy Style',
      description: 'Classic, tailored, polished, nautical, sophisticated',
      list: [
        'An establishing shot capturing the full-body of a polished, chic woman figure in a classic preppy look. She is dressed in a crisp white button-down shirt tucked into tailored navy Bermuda shorts. The look is completed with brown leather boat shoes and a pearl necklace. The backdrop is a sophisticated, coastal setting, subtly enhancing the timeless East Coast vibe.',
        'An establishing shot capturing the full-body of a refined woman figure embodying the preppy aesthetic. She is dressed in a pastel gingham button-down shirt layered under a navy blazer with gold buttons, paired with tailored khaki trousers. The look features brown loafers and a braided belt. The backdrop is a polished, academic setting, subtly reinforcing the Ivy League-inspired elegance.',
        'An establishing shot capturing the full-body of a playful, sophisticated woman figure in a preppy look. She is dressed in a striped navy and white Breton top paired with tailored red shorts. The look is completed with white boat shoes and a light blue blazer. The backdrop is a sunny coastal scene, subtly enhancing the polished, casual feel of the outfit.',
        'An establishing shot capturing the full-body of a fashionable woman figure in a preppy look. She is dressed in a pastel pink Oxford shirt paired with high-waisted plaid pants. The look features tan leather loafers and a silk scarf tied around the neck. The backdrop is a classic, upscale setting, subtly enhancing the refined, sophisticated charm of the outfit.',
      ],
    },
  ];

  if (createItem.type === null) {
    return (
      <div className="flex h-full min-w-[22.5rem] flex-col gap-6 border-r border-secondary/50 py-6 px-2 lg:px-6">
        <div
          className={`flex cursor-pointer items-center gap-x-2 rounded ${isHome === true ? 'bg-primary text-white' : 'border-primary bg-transparent text-black hover:border-primary hover:text-primary'} border border-transparent px-4 py-2 text-[1rem] font-bold leading-[1.75rem]`}
          onClick={() => {
            navigate('/studio/home');
            setOpenTools(false);
          }}
        >
          <Home size={16} />
          <h2>Home</h2>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-bold text-primary">Create</h3>
          <div className="flex flex-col gap-0">
            <SidebarButton
              variant="nav"
              icon={Plus}
              text="Create an Apparel"
              onClick={() =>
                handleTitleIconChanges(Plus, 'Create an Apparel', 'apparel')
              }
            />
            <SidebarButton
              variant="nav"
              icon={SquareAsterisk}
              text="Create a Pattern"
              onClick={() => {
                handleTitleIconChanges(
                  SquareAsterisk,
                  'Create a Pattern',
                  'pattern',
                );
                setGenerateSize('Square');
              }}
            />
            <SidebarButton
              variant="nav"
              icon={NotebookPen}
              text="Create from Sketch"
              onClick={() =>
                handleTitleIconChanges(
                  NotebookPen,
                  'Create from Sketch',
                  'sketch',
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-bold text-primary">Edit Tools</h3>
          <div className="flex flex-col gap-0">
            {import.meta.env.VITE_APP_ENV === 'development' && (
              <SidebarButton
                variant="nav"
                icon={SquareAsterisk}
                text="Apply a Pattern"
                onClick={() =>
                  handleTitleIconChanges(
                    SquareAsterisk,
                    'Apply a Pattern',
                    'apply-pattern',
                  )
                }
              />
            )}
            <SidebarButton
              variant="nav"
              icon={Eraser}
              text="Remove Background"
              onClick={() =>
                handleTitleIconChanges(Eraser, 'Remove Background', 'removeBg')
              }
            />
            <SidebarButton
              variant="nav"
              icon={Replace}
              text="Upscale Enhance"
              onClick={() =>
                handleTitleIconChanges(Replace, 'Upscale Enhance', 'upscale')
              }
            />
            <SidebarButton
              variant="nav"
              icon={ImagePlus}
              text="Inpainting"
              onClick={() =>
                handleTitleIconChanges(ImagePlus, 'Inpainting', 'inpainting')
              }
            />
            {import.meta.env.VITE_APP_ENV === 'development' && (
              <SidebarButton
                variant="nav"
                icon={Palette}
                text="Change Color"
                onClick={() =>
                  handleTitleIconChanges(
                    Palette,
                    'Change Color',
                    'change-color',
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full min-w-[22.5rem] flex-col justify-start gap-6 overflow-hidden border-r border-secondary/50">
      <div
        className="sticky top-0 z-10 flex flex-col gap-4 bg-white px-6 pt-4"
        id="header"
      >
        <SidebarHeader
          variant="back"
          text="Menu"
          onClick={() => {
            clearDesignTask();
            handlePromptChange({ target: { value: '' } });
            setSuggestedPrompts([]);
            setDescribing(false);
            setCreateItem({ icon: null, text: null, type: null });
          }}
        />
        <SidebarHeader icon={createItem.icon} text={createItem.text} />
      </div>
      <div
        className="flex h-full flex-col justify-between gap-6 overflow-y-auto px-6"
        style={{ maxHeight: 'calc(100% - 3.5rem)' }}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <SidebarTitle text="Describe the Design" />
            <Textarea
              variant="studio"
              className="focus-visible:none h-[6.75rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-8 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
              placeholder="Type a brief description, then wait a moment for AI-generated prompts to appear..."
              value={positivePrompt}
              onChange={handlePromptChange}
              icon={HelpCircle}
              onIconClick={() => {
                setDescribing((prev) => !prev);
                setGenerateType('templates');
              }}
              onClick={() => {
                setDescribing(true);
                setGenerateType('templates');
              }}
            />
          </div>
          {describing && (
            <div
              className={cn(
                'flex flex-col gap-y-4 transition-all duration-1000 ease-in-out transform opacity-0',
                describing && 'opacity-100 translate-y-0',
              )}
              style={{ transitionProperty: 'opacity, transform' }}
            >
              <div id="toggle group">
                <ToggleGroup type="single" className="gap-x-0">
                  {(positivePrompt.length > 0 ||
                    suggestedPrompts.length > 0) && (
                    <ToggleGroupItem
                      variant="nav"
                      value="ai-gen"
                      active={generateType === 'ai-gen'}
                      onClick={() => setGenerateType('ai-gen')}
                    >
                      <Sparkles size={16} className="pr-0.5" />
                      AI
                    </ToggleGroupItem>
                  )}
                  <ToggleGroupItem
                    variant="nav"
                    value="templates"
                    active={generateType === 'templates'}
                    onClick={() => setGenerateType('templates')}
                  >
                    <Copy size={16} className="pr-0.5" />
                    Templates
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    variant="nav"
                    value="history"
                    active={generateType === 'history'}
                    onClick={() => setGenerateType('history')}
                  >
                    <History size={16} className="pr-0.5" />
                    History
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
              {generateType === 'ai-gen' && promptLoading && (
                <div className="flex flex-col gap-y-2">
                  {Array.from({ length: 5 }).map((_, i) => (
                    <SidebarCard
                      key={i}
                      index={i}
                      variant="ghost"
                      value="Your prompt is being generated.."
                    />
                  ))}
                </div>
              )}
              {generateType === 'ai-gen' && !promptLoading && (
                <div className="flex flex-col gap-y-2">
                  {suggestedPrompts.map((advice, i) => (
                    <SidebarCard
                      key={i}
                      index={i}
                      value={upperFirst(advice.value)}
                      selectedId={selectedAdviceId}
                      onClick={() =>
                        handleSelectedPrompt(i, advice.value, advice.key)
                      }
                    />
                  ))}
                </div>
              )}
              {generateType === 'ai-gen' &&
                !promptLoading &&
                !suggestedPrompts.length === 0 &&
                promptError && (
                  <div className="flex flex-col gap-y-2">
                    <SidebarCard
                      value="Failed to generate prompt"
                      variant="error"
                    />
                  </div>
                )}
              {generateType === 'templates' && (
                <div className="flex flex-col gap-y-2">
                  {selectedTemplateId === null ? (
                    (createItem.type === 'pattern'
                      ? patternTemplates
                      : templates
                    ).map((template, i) => (
                      <CategoryCard
                        key={i}
                        category={template}
                        index={i}
                        setId={setSelectedTemplateId}
                      />
                    ))
                  ) : (
                    <div className="flex flex-col gap-y-2">
                      <SidebarHeader
                        icon={ChevronLeft}
                        text={
                          createItem.type === 'pattern'
                            ? patternTemplates[selectedTemplateId].title
                            : templates[selectedTemplateId].title
                        }
                        onClick={() => {
                          setSelectedTemplateId(null);
                          setSelectedTemplate({
                            selectedTemplateId: null,
                            selectedTemplateIndex: null,
                          });
                        }}
                        variant="back"
                      />
                      {(createItem.type === 'pattern'
                        ? patternTemplates[selectedTemplateId]
                        : templates[selectedTemplateId]
                      ).list.map((item, i) => (
                        <SidebarCard
                          key={i}
                          index={i}
                          value={item}
                          selectedId={selectedTemplate.selectedTemplateIndex}
                          onClick={() => {
                            setSelectedTemplate({
                              selectedTemplateId,
                              selectedTemplateIndex: i,
                            });
                            setPositivePrompt(item);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {generateType === 'history' && (
                <div className="flex flex-col gap-y-2">
                  {descHistory.slice(0, 5).map((desc, i) => (
                    <SidebarCard
                      key={i}
                      index={i}
                      value={desc}
                      selectedId={selectedAdviceId}
                      onClick={() => {
                        setSelectedAdviceId(i);
                        setPositivePrompt(desc);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          {!addNegativeKeywords ? (
            <SidebarButton
              icon={Plus}
              text="Add Negative Keywords"
              className="gap-2 px-4 py-2"
              onClick={() => setAddNegativeKeywords(!addNegativeKeywords)}
            />
          ) : (
            <div className="flex flex-col gap-y-2">
              <SidebarTitle text="Negative Keywords" />
              <Textarea
                variant="studio"
                icon={Trash}
                onIconClick={() => setAddNegativeKeywords(!addNegativeKeywords)}
                value={negativePrompt}
                onChange={(e) => {
                  setNegativePrompt(e.target.value);
                }}
                className="focus-visible:none h-[6.75rem] resize-none rounded border border-night/5 bg-secondary/5 py-2 pr-8 text-night transition duration-300 ease-in-out placeholder:text-night/70 hover:border-primary hover:bg-[#FFFDFD] focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none"
                placeholder="Describe details you don't want in your design"
              />
            </div>
          )}
          {/* Upload Images */}
          {createItem.type === 'apparel' && (
            <div className="flex flex-row gap-x-4">
              <UploadBox
                title="Reference Image"
                icon={ImagePlus}
                text="Drop an image or click to upload"
                type="image"
                fileEntityType="APPAREL"
                onClick={() => console.log('Upload Image')}
                editImage={{
                  id: refImages?.images[0]?.studioItemId,
                  name: 'image',
                  type: 'image',
                  url: refImages?.images[0]?.url,
                  entityType: refImages?.images[0]?.entityType,
                  entityId: refImages?.images[0]?.entityId,
                }}
              />
              {
                <UploadBox
                  title="Reference Pattern"
                  icon={SquareAsterisk}
                  text="Drop a pattern or click to upload"
                  type="pattern"
                  fileEntityType="PATTERN"
                  onClick={() => console.log('Upload Pattern')}
                  editImage={{
                    id: refImages?.patterns[0]?.studioItemId,
                    name: 'pattern',
                    type: 'pattern',
                    url: refImages?.patterns[0]?.url,
                    entityType: refImages?.patterns[0]?.entityType,
                    entityId: refImages?.patterns[0]?.entityId,
                  }}
                />
              }
            </div>
          )}
          {createItem.type === 'pattern' && (
            <div className="flex w-1/2 flex-row gap-x-4">
              <UploadBox
                title="Reference Pattern"
                icon={SquareAsterisk}
                text="Drop a pattern or click to upload"
                type="pattern"
                fileEntityType="PATTERN"
                editImage={{
                  id: refImages?.patterns?.[0]?.studioItemId,
                  name: 'pattern',
                  type: 'pattern',
                  url: refImages?.patterns?.[0]?.url,
                  entityType: refImages?.patterns?.[0]?.entityType,
                  entityId: refImages?.patterns?.[0]?.entityId,
                }}
              />
            </div>
          )}
          {createItem.type === 'sketch' && (
            <div className="flex flex-row gap-x-4">
              <UploadBox
                title="Reference Sketch"
                icon={ImagePlus}
                text="Drop a sketch or click to upload"
                type="image"
                fileEntityType="SKETCH"
                onClick={() => console.log('Upload Image')}
                editImage={{
                  id: refImages?.sketches[0]?.studioItemId,
                  name: 'sketch',
                  type: 'sketch',
                  url: refImages?.sketches[0]?.url,
                  entityType: refImages?.sketches[0]?.entityType,
                  entityId: refImages?.sketches[0]?.entityId,
                }}
              />
              <UploadBox
                title="Reference Pattern"
                icon={SquareAsterisk}
                text="Drop a pattern or click to upload"
                type="pattern"
                fileEntityType="PATTERN"
                disabled
                onClick={() => console.log('Upload Pattern')}
                editImage={{
                  id: refImages?.patterns[0]?.studioItemId,
                  name: 'pattern',
                  type: 'pattern',
                  url: refImages?.patterns[0]?.url,
                  entityType: refImages?.patterns[0]?.entityType,
                  entityId: refImages?.patterns[0]?.entityId,
                }}
              />
            </div>
          )}
          {referenceError && (
            <div className="text-sm font-normal text-red-600">
              {referenceError}
            </div>
          )}
          <div className="flex flex-row">
            <Accordion className="w-full" type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger>
                  <SidebarTitle text="Additional Settings" />
                </AccordionTrigger>
                <AccordionContent>
                  <SidebarTitle text="Size" />
                  <ToggleGroup type="single" className="gap-x-2 pt-4">
                    <ToggleGroupItem
                      variant="studio"
                      value="Square"
                      active={generateSize === 'Square'}
                      onClick={() => setGenerateSize('Square')}
                    >
                      Square
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      variant="studio"
                      value="Portrait"
                      active={generateSize === 'Portrait'}
                      onClick={() => setGenerateSize('Portrait')}
                    >
                      Portrait
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      variant="studio"
                      value="Landscape"
                      active={generateSize === 'Landscape'}
                      onClick={() => setGenerateSize('Landscape')}
                    >
                      Landscape
                    </ToggleGroupItem>
                  </ToggleGroup>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
      <div id="bottom" className="flex shrink-0 flex-col gap-6 px-6 pb-4">
        <div className="flex flex-col gap-4">
          <SidebarTitle text="Visibility" />
          <div className="flex flex-row items-center justify-center gap-x-4 [&>*]:w-full">
            <ToggleGroup type="single" className="gap-x-2">
              <ToggleGroupItem
                variant="studio"
                value="PUBLIC"
                active={generateVisibility === 'PUBLIC'}
                onClick={() => setGenerateVisibility('PUBLIC')}
              >
                <Earth size={16} className="mr-1" />
                Public
              </ToggleGroupItem>
              <ToggleGroupItem
                variant="studio"
                value="PRIVATE"
                active={generateVisibility === 'PRIVATE'}
                disabled={!isPrivateImageEnabled}
                onClick={() => {
                  if (isPrivateImageEnabled) {
                    setGenerateVisibility('PRIVATE');
                  }
                }}
              >
                <Lock size={16} className="mr-1" />
                Private
              </ToggleGroupItem>
            </ToggleGroup>
          </div>
        </div>
        <SidebarButton
          variant="generate"
          text="Generate"
          icon={Wand}
          onClick={() => handleGenerate()}
        />
      </div>
    </div>
  );
};
