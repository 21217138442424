import { useEffect } from 'react';
import { X } from 'lucide-react';

import { useStudioContext } from '@/contexts/StudioContext';
import { SaveToCollection } from '@/layouts/common/index';

import { SidebarButton } from './components/SidebarMenu';
import { ImagePreview, TileImage } from './components/StudioContents';
import { GenerationElapsed } from './components/StudioUtils';

const useImageDisplay = (generatedImages, orientation) => {
  const {
    isGenerating,
    isTile,
    isPreview,
    selectedGeneratedImage,
    setSelectedGeneratedImage,
    setIsPreview,
    setIsTile,
    openTools,
    setOpenTools,
  } = useStudioContext();

  useEffect(() => {
    if (generatedImages.length > 0) {
      setIsTile(true);
      setIsPreview(false);
    }
  }, [generatedImages]);

  const handleImageClick = (index) => {
    setSelectedGeneratedImage(index);
    setIsPreview(true);
    setIsTile(false);
  };

  const closePreview = () => {
    setIsPreview(false);
    setIsTile(true);
    if (openTools === true) setOpenTools(false);
  };

  return {
    isGenerating,
    isTile,
    isPreview,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
  };
};

const ImageHeader = ({ isGenerating, isTile }) => {
  if (isGenerating) {
    return (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">
        Generate <GenerationElapsed />
      </h2>
    );
  }
  if (isTile) {
    return (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">Result</h2>
    );
  }
  return null;
};

const ImageContent = ({
  isGenerating,
  isTile,
  isPreview,
  generatedImages,
  orientation,
  selectedGeneratedImage,
  handleImageClick,
  closePreview,
  downloadFile,
}) => {
  if (isGenerating) {
    return <GeneratingPlaceholder />;
  }
  if (isTile) {
    return (
      <TileView
        images={generatedImages}
        orientation={orientation}
        onImageClick={handleImageClick}
      />
    );
  }
  if (isPreview) {
    return (
      <>
        <PreviewHeader
          selectedImage={generatedImages[selectedGeneratedImage]}
          onClose={closePreview}
          downloadFile={downloadFile}
        />
        <ImagePreview
          images={generatedImages}
          activeIndex={selectedGeneratedImage}
          setActiveIndex={handleImageClick}
        />
      </>
    );
  }
  return null;
};

const GeneratingPlaceholder = () => (
  <div className="flex flex-col gap-y-6">
    <div className="ghost flex aspect-square min-h-[20rem] items-center justify-center sm:min-h-[32rem]"></div>
  </div>
);

const TileView = ({ images, orientation, onImageClick }) => {
  const formattedStr = orientation.toLowerCase();
  const aspect =
    formattedStr === 'square'
      ? '[1/1]'
      : formattedStr === 'portrait'
        ? '[3/4]'
        : '[4/3]';
  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-6">
      {images.map((image, index) => (
        <TileImage
          key={index}
          imageUrl={image.url}
          itemId={image.studioItemId}
          aspect={aspect}
          alt={image.studioItemId}
          onClick={() => onImageClick(index)}
        />
      ))}
    </div>
  );
};

const PreviewHeader = ({ selectedImage, onClose, downloadFile }) => (
  <div className="flex items-center justify-between">
    <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">Result</h2>
    <div className="flex items-start gap-x-4 pb-4">
      <SaveToCollection
        id={selectedImage.studioItemId}
        type="studioItem"
        variant="studio"
      />
      <SidebarButton
        text="Download"
        className="py-2 px-4 font-bold"
        onClick={() => downloadFile(selectedImage.url, 'ai-generated-image')}
      />
      <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
        <X size={36} onClick={onClose} strokeWidth={1.5} />
      </div>
    </div>
  </div>
);

export const ImageDisplay = ({
  generatedImages,
  orientation,
  downloadFile,
}) => {
  const {
    isGenerating,
    isTile,
    isPreview,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
  } = useImageDisplay(generatedImages, orientation);

  return (
    <div className="flex max-w-[50rem] flex-col rounded-lg border border-secondary/50 bg-primaryLight p-6">
      <ImageHeader
        isGenerating={isGenerating}
        isTile={isTile}
        isPreview={isPreview}
      />
      <ImageContent
        isGenerating={isGenerating}
        isTile={isTile}
        isPreview={isPreview}
        generatedImages={generatedImages}
        orientation={orientation}
        selectedGeneratedImage={selectedGeneratedImage}
        handleImageClick={handleImageClick}
        closePreview={closePreview}
        downloadFile={downloadFile}
      />
    </div>
  );
};
